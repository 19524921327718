import React, { useEffect, useState } from "react";
import { Spin, Row, Col, Descriptions } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import NotificacionesApi from "../api/notificacionesApi";
import PageContainer from "../../../components/pageContainer";
import parser from "../../../helpers/parser";
import NotifStatusTag from "./notifStatusTag";

const DescItem = Descriptions.Item;

const NotificacionesView = props => {
  const navigate = useNavigate();
  const params = useParams();
  const [notificacion, setNotificacion] = useState(undefined);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadNotificacion();
  }, []);

  const loadNotificacion = async () => {
    setWorking(true);
    try {
      const response = await NotificacionesApi.get(params.id);
      setNotificacion(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title={`Notificación`}
      onBack={() => navigate(-1)}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Descriptions size="small" column={1} bordered>
              <DescItem label="Título"><b>{notificacion?.title}</b></DescItem>
              <DescItem label="Descripción"><b>{notificacion?.body}</b></DescItem>
              <DescItem label="Estado"><NotifStatusTag status={notificacion?.status} /></DescItem>
              {notificacion?.count_total != undefined && <DescItem label="Envios totales"><b>{notificacion?.count_total}</b></DescItem>}
              {notificacion?.count_success != undefined && <DescItem label="Envios exitosos"><b>{notificacion?.count_success}</b></DescItem>}
              {notificacion?.count_error != undefined && <DescItem label="Envios con error"><b>{notificacion?.count_error}</b></DescItem>}
              {notificacion?.count_not_send != undefined && <DescItem label="Sin enviar"><b>{notificacion?.count_not_send}</b></DescItem>}
              <DescItem label="Enviada el"><b>{parser.datetime(notificacion?.created_at)}</b></DescItem>
              <DescItem label="Creado por"><b>{notificacion?.user?.email}</b></DescItem>
            </Descriptions>
          </Col>
        </Row>
      </Spin>
    </PageContainer>
  );
}

export default NotificacionesView;

