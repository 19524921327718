import React, { useContext, useEffect } from 'react';
import { Form, Input, Table } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import parser from '../helpers/parser';
import DeleteButton from './buttons/deleteButton';

const FilesTable = props => {
  const columns = [
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: text => <LinkOutlined />
    },
    { title: 'Nombre', dataIndex: 'name', },
    {
      title: 'Tamaño', dataIndex: ['file', 'size'], width: '20%', align: 'center',
      render: text => <span>{parser.parseFileSize(text)}</span>
    },
    { title: 'Tipo', dataIndex: ['file', 'type'], width: '20%', align: 'center' },
    {
      title: 'Imagen', dataIndex: 'image', width: '20%', align: 'center',
      render: (text, record) => record.file?.type?.indexOf('image') > -1
        ? <img alt="img" src={record.image} height={50} width={50} />
        : ''
    },
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        props.showActions === true
          ? <DeleteButton /*hideConfirm={true}*/ onConfirm={() => props.onDelete(record.file.uid)} />
          : ''
      )
    },
  ];

  return (
    <Table
      rowKey={record => record.file?.uid}
      columns={columns}
      dataSource={props.files}
      pagination={{ hideOnSinglePage: true, pageSize: 5, showSizeChanger: false }}
      size="small"
    />
  );
}

export default FilesTable;