import Fetcher from "../../../helpers/fetcher";

const ForgotPassApi = {
  changePassword: (token, email, dni, password) => {
    let form = new FormData();
    form.append('token', token);
    form.append('email', email);
    form.append('dni', dni);
    form.append('password', password);

    return Fetcher
      .post("auth/single-user-change-password", form)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  },
}

export default ForgotPassApi;