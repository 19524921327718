import styled from "styled-components";

const Container = styled('div')`
  border-radius: 10px;
  border: 1px dashed black;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NoDataItem = props => {
  return (
    <Container>
      {props.icon}
      <small style={{ flex: 1, marginLeft: 4 }}>{props.message}</small>
    </Container>
  )
}

export default NoDataItem;