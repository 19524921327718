import styled from "styled-components";

const StyledViewport = styled('div')`
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const StyledViewportCover = styled('div')`
  background: linear-gradient(to right, #444, #6f6f6f);
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.80;
  overflow: hidden;
`

const StyledMain = styled('div')`
  background-color: ${props => props.theme.token?.colorBgContainer};
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -230px;
  width: 450px;
  padding: 24px;
  box-shadow: 0 0 100px rgba(0, 0, 0, .08);
  border-radius: 20px;

  @media (max-width: 767px) {
    top: 0;
    left: 0;
    margin: 5%;
    width: 90%;
    height: 450px;
  }
`

const StyledLogo = styled('div')`
  text-align: center;
  line-height: 40px;
  margin-bottom: 24px;

  img {
    width: 200px;

    @media (max-width: 767px) {
      margin: 0;
      width: 80px;
    }
  }
`

const StyledFooter = styled('div')`
  display: flex;
  justify-content: right;
  align-items: center;

  img {
    height: 12px;
  }

  small {
    color: ${props => props.theme.token?.colorText};
  }
`

const StyledLoaderViewport = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoader = styled('img')`
  @keyframes stretch {
    to { transform: scale(0.4); }
  }
  width: 200px;
  height: 100px;
  animation: stretch .5s infinite alternate;
	transform-origin: center;
`;

export {
  StyledViewport as Viewport,
  StyledViewportCover as ViewportCover,
  StyledMain as Main,
  StyledLogo as Logo,
  StyledFooter as Footer,
  StyledLoaderViewport as LoaderViewport,
  StyledLoader as Loader,
}