
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import RubrosList from "./components/rubrosList";
 
const RubrosPage = () => {
  return (
    <Routes>
      <Route index element={<RubrosList />} />
    </Routes>
  );
}

export default RubrosPage;