
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import PlantasList from "./components/plantasList";
import PlantasForm from "./components/plantasForm";
import PlantasView from "./components/plantasView";
 
const PlantasPage = () => {
  return (
    <Routes>
      <Route index element={<PlantasList />} />
      <Route path="/new" element={<PlantasForm />} />
      <Route path="/edit/:id" element={<PlantasForm />} />
      <Route path="/view/:id" element={<PlantasView />} />
    </Routes>
  );
}

export default PlantasPage;