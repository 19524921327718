import { Spin } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import DescuentosClubApi from "../../pages/descuentos_club/api/descuentosClubApi";
import DescuentosCategoriaList from "./home/components/descuentosCategoriaList";

const Content = styled('div')`
  flex: 1;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: scroll;
  height: 90vh;
`;

const PdODescCatList = props => {
  const [descuentos, setDescuentos] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadDescuentos();
  }, []);

  const loadDescuentos = async () => {
    setWorking(true);
    try {
      const response = await DescuentosClubApi.get();
      setDescuentos(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Spin spinning={working}>
        <Content>
          <DescuentosCategoriaList
            descuentos={descuentos}
            showBack
            onBackClick={() => props.setNavigation({ view: 'home', state: {} })}
            setNavigation={props.setNavigation}
          />
        </Content>
      </Spin>
    </div>
  )
}

export default PdODescCatList;