import { useEffect, useState } from 'react';
import { Space } from 'antd';
import LoginForm from "./ForgotPassForm";
import logo from '../assets/logoblue2x.png';
import logob12 from '../assets/b12logo.png';
import logohyc from '../assets/logoHyC.png';
import { Main, Logo, Footer, Title, Text, Content, Section } from './ForgotPassPage.style';
import { useParams } from 'react-router-dom';

const ForgotPassPage = () => {
  const { token } = useParams();
  return (
    <Content>
      <Main>
        <Logo>
          <img src={logo} alt="logo" />
        </Logo>

        <Title>¡Hola!</Title>

        <Text>Si llegaste aqui es porque solicitaste modificar tu contraseña</Text>
        <Text>Por favor completa los siguiente datos para realizar la modificación</Text>

        <Section>
          <LoginForm token={token} />
        </Section>

        <Footer>
          <Space>
            <small>Powered by</small>
            <img src={logob12} alt="b12" />
            <img src={logohyc} alt="hyc" />
          </Space>
        </Footer>
      </Main>
    </Content>
  )
}

export default ForgotPassPage;