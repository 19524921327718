import { Routes, Route } from "react-router-dom";
import ForgotPassPage from "./components/ForgotPassPage";

const ForgotPassContainer = () => {
  return (
    <Routes>
      <Route path="/:token" element={<ForgotPassPage />} />
    </Routes>
  );
}

export default ForgotPassContainer;
