import { Descriptions, Drawer } from "antd"

const DescItem = Descriptions.Item;

const RubrosDetail = props => {
  return (
    <Drawer
      title="Rubro"
      placement="right"
      onClose={props.onClose}
      open={props.open}
    >
      <Descriptions size="small" column={1} bordered>
      <DescItem label="Rubro"><b>{ props.rubro?.codigo_rubro }</b></DescItem>
      <DescItem label="Subrubro"><b>{ props.rubro?.codigo_subrubro }</b></DescItem>
      <DescItem label="Descripción"><b>{ props.rubro?.descripcion }</b></DescItem>
    </Descriptions>
    </Drawer>
  )
}

export default RubrosDetail;