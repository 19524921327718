export const DefaultTheme = {
  token: {
    colorPrimary: '#0a6bf5',
    colorPrimaryLight: '#2196f3',
    colorPrimaryDark: '#000d4a',
    colorSecondary: '#2196f3',
    /*colorPrimary: '#061B3F',
    colorPrimaryLight: '#465c86',
    colorPrimaryDark: '#0e063f',
    colorSecondary: '#C9AF5B',*/
    borderRadius: 16,
  },

  colors: {
    primary: '#0a6bf5',

    grey2: '#BDBDBD',
    grey3: '#4b4751',
    grey4: '#e0e0e2',
    orange: '#ff9c00',

    black: '#000000',
    blue: '#2196f3',
    darkBlue: '#000d4a',
    lightBlue: '#0a6bf5',
  },

  components: {
    Layout: {
      colorBgHeader: '#0a6bf5',
      colorText: 'white',
    },
    Menu: {
      colorItemBg: '#0a6bf5',
      colorSubItemBg: '#0a6bf5',
      colorItemText: 'white',
      colorItemTextHover: 'white',
      colorItemTextSelected: 'white',
      itemSelectedBg: '#000d4a',

      logo: {
        height: 60,
        heightCollapsed: 50,
        width: 100,
        widthCollapsed: 50,
      },
    },
    ShadowCard: {
      boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.75)',
    }
  },

  sizes: {
    h1: 60,
    h2: 50,
    h3: 40,
    h4: 30,
    h5: 20,
    title: 18,
    subtitle: 16,
    p: 14,
    small: 12,
    tiny: 10,
  },
}