import { Routes, Route } from "react-router-dom";
import LoginPage from "./components/loginPage/LoginPage";

const LoginContainer = () => {
  return (
    <Routes>
      <Route index element={<LoginPage />} />
    </Routes>
  );
}

export default LoginContainer;
