import { useEffect, useState } from "react";
import { Layout } from "antd";
import { Route, Routes, useLocation } from "react-router-dom";
import { Container, CSSTransition, RouteSection, TransitionGroup } from "./StyledAppContent";
import AuthHelper from "../../../helpers/authHelper";

const { Content } = Layout;

const AppContent = props => {
  const [routes, setRoutes] = useState([]);
  const [user] = useState(AuthHelper.getUser());
  const location = useLocation();

  useEffect(() => {
    if (props.routes) {
      let routesAux = getRoutes(props.routes);
      setRoutes(routesAux);
    }
  }, [props.routes]);

  const getRoutes = items => {
    let routesAux = items.filter(x => x.path);

    let itemsWithChildren = items.filter(x => x.children?.length > 0);
    itemsWithChildren.map(item => {
      routesAux = [...routesAux, ...getRoutes(item.children)];
      return item;
    });

    return routesAux;
  };

  return (
    <Content>
      <Container>
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={1000}
          >
            <RouteSection>
              <Routes>
                {/*<Route key={'/'} path="*" element={<DashboardPage />} />*/}
                {routes.map(route => {
                  if (route.roles.includes(user?.role)) {
                    return <Route key={route.key} path={route.path} element={route.element} />
                  }
                })}
              </Routes>
            </RouteSection>
          </CSSTransition>
        </TransitionGroup>
      </Container>
    </Content>
  );
}

export default AppContent;