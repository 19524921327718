
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import BannersList from "./components/bannersList";
import BannersView from "./components/bannersView";
 
const BannersPage = () => {
  return (
    <Routes>
      <Route index element={<BannersList />} />
      <Route path="/view/:id" element={<BannersView />} />
    </Routes>
  );
}

export default BannersPage;