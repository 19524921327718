import { Descriptions, Drawer, Tag } from "antd"
import { Consts } from "../../../../helpers/consts";

const DescItem = Descriptions.Item;

const ParametrosDetail = props => {
  return (
    <Drawer
      title="Parámetro"
      placement="right"
      onClose={props.onClose}
      open={props.open}
    >
      <Descriptions size="small" column={1} bordered>
        <DescItem label="Clave"><b>{props.parametro?.clave}</b></DescItem>
        <DescItem label="Valor"><b>{
          props.parametro?.clave === Consts.PARAMETROS.GEOLOCALIZACION
            ? <Tag>{props.parametro?.valor === "True" ? 'Habilitado' : 'Deshabilitado'}</Tag>
            : props.parametro?.valor
        }</b></DescItem>
        <DescItem label="Descripción"><b>{props.parametro?.descripcion}</b></DescItem>
      </Descriptions>
    </Drawer>
  )
}

export default ParametrosDetail;