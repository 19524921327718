import styled from "styled-components";
import { DefaultTheme } from "../../styles/AppTheme";

const DefaultHeader = styled('div')`
  height: 100px;
  width: 100%;
  display: flex;
`;

const DefaultContent = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
  background-color: ${DefaultTheme.token.colorPrimaryLight};
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
`;

const DefaultHeaderText = styled('h5')`
  padding: 15px 0px;
  color: white;
  font-size: 18px;
`;

const PdOHeader = props => {
  return (
    <DefaultHeader>
      <DefaultContent>
        <DefaultHeaderText h5>{props.header}</DefaultHeaderText>
      </DefaultContent>
    </DefaultHeader>
  )
}

export default PdOHeader;