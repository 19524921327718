import React, { useEffect, useState } from "react";
import GruposApi from "../api/gruposApi";
import PageContainer from "../../../../components/pageContainer";
import TableSearch from "../../../../components/tableSearch";
import { Col, Row, Spin } from "antd";
import GruposTable from "./gruposTable";
import { useNavigate } from "react-router-dom";

const GruposList = props => {
  const navigate = useNavigate();
  const [grupos, setGrupos] = useState([]);
  const [gruposFiltrados, setGruposFiltrados] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadGrupos();
  }, []);

  useEffect(() => {
    filterGrupos();
  }, [searchText]);

  const loadGrupos = async () => {
    setWorking(true);
    try {
      const response = await GruposApi.get();
      setGrupos(response);
      setGruposFiltrados(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false)
  }

  const filterGrupos = () => {
    let gruposAux = [...grupos];
    if (searchText != '') {
      gruposAux = gruposAux.filter(x =>
        x.numero_grupo.toString().toLowerCase().indexOf(searchText) > -1 ||
        x.descripcion.toLowerCase().indexOf(searchText) > -1
      )
    }
    setGruposFiltrados(gruposAux);
  }

  return (
    <PageContainer
      title="Grupos/Sectores"
      onBack={() => navigate(-1)}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <TableSearch
              style={{ width: '100%' }}
              placeholder="Buscar por numero, descripción"
              value={searchText}
              onChange={e => setSearchText(e.target.value.toLowerCase())}
            />
          </Col>
          <Col span={24}>
            <GruposTable
              grupos={gruposFiltrados}
            />
          </Col>
        </Row>
      </Spin>
    </PageContainer>
  );
}

export default GruposList;