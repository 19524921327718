import { Avatar, Segmented } from "antd";
import { FaBarcode, FaBell, FaHome, FaUser } from "react-icons/fa";
import { TbMapPinFilled } from "react-icons/tb";
import styled from "styled-components";

const Icon = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
`;

const PdoNavigator = props => {
  return (
    <Segmented
      block
      onChange={value => props.setNavigation({ view: value, state: {} })}
      value={props.navigation.view}
      options={[
        {
          label: (
            <Icon>
              <FaHome style={{ color: 'gray' }} />
              <small>Inicio</small>
            </Icon>
          ),
          value: 'home',
        },
        {
          label: (
            <Icon>
              <TbMapPinFilled style={{ color: 'gray' }} />
              <small>Mapa</small>
            </Icon>
          ),
          value: 'plantas',
        },
        {
          label: (
            <Icon>
              <Avatar icon={<FaBarcode style={{ marginTop: 6 }} />} style={{ position: 'absolute', top: -15 }} />
              <small style={{ marginTop: 14 }}>Escaner</small>
            </Icon>
          ),
          value: 'scanner',
        },
        {
          label: (
            <Icon>
              <FaUser style={{ color: 'gray' }} />
              <small>Perfil</small>
            </Icon>
          ),
          value: 'perfil',
        },
        {
          label: (
            <Icon>
              <FaBell style={{ color: 'gray' }} />
              <small>Notifica...</small>
            </Icon>
          ),
          value: 'notificaciones',
        },
      ]}
    />
  )
}

export default PdoNavigator;