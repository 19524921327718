import { Layout } from "antd";
import styled from "styled-components";

const { Content } = Layout;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

const StyledMain = styled('div')`
  background-color: ${props => props.theme.token?.colorBgContainer};
  width: 550px;
  padding: 24px;
  box-shadow: 0 0 100px rgba(0, 0, 0, .08);
  border-radius: 20px;
  border: 1px solid #BDBDBD;

  @media (max-width: 767px) {
    flex: 1;
    width: 100%;
  }
`

const StyledLogo = styled('div')`
  text-align: center;
  line-height: 40px;
  margin-bottom: 24px;

  img {
    width: 100px;

    @media (max-width: 767px) {
      margin: 0;
      width: 80px;
    }
  }
`

const StyledTitle = styled('h1')`
  font-size: 35px;
  font-weight: 300;
  text-align: center;
  text-transform: capitalize;
`;

const StyledText = styled('p')`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
`;

const StyledSection = styled('div')`
  margin-top: 40px;
`;

const StyledFooter = styled('div')`
  display: flex;
  justify-content: right;
  align-items: center;

  img {
    height: 12px;
  }

  small {
    color: ${props => props.theme.token?.colorText};
  }
`

export {
  StyledContent as Content,
  StyledMain as Main,
  StyledLogo as Logo,
  StyledTitle as Title,
  StyledText as Text,
  StyledSection as Section,
  StyledFooter as Footer,
}