import { Col, Row } from "antd";
import NoDataItem from "./nodataItem";
import { TbTicket } from "react-icons/tb";
import CuponItem from "./cuponItem";

const CuponesList = props => {
  return (
    <Row gutter={[16, 0]}>
      <Col span={24}>
        <h4 style={{ marginLeft: 8 }}>Cupones</h4>
        {props.cupones?.length > 0
          ? props.cupones?.map(cupon => (
            <CuponItem
              icon={<TbTicket />}
              cupon={cupon}
            />
          ))
          : <NoDataItem icon={<TbTicket size={30} />} message="No tienes cupones nuevos" />}
      </Col>
    </Row>
  )
}

export default CuponesList;