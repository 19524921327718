import styled from "styled-components";
import { Layout } from 'antd';
import { DefaultTheme } from "../../../styles/AppTheme";

const StyledHeader = styled(Layout.Header)`
  margin: 8px 16px;
  padding: 0px 16px !important;
  border-radius: ${props => props.theme.token?.borderRadius}px;
  ${props => props.test === true
    ? `background-color: ${DefaultTheme.colors.orange} !important;`
    : `background-color: ${props.theme.token?.colorPrimary} !important;`}
`

const StyledMenuIcon = styled('span')`
  .anticon {
    display: 'inline-block';
    vertical-align: 'middle';
    margin-right: 4px;
    font-size: 18px;

    @media (max-width: 600px) {
      .header-icon {
        font-size: 12px;
      }

      .header-title {
        font-size: 10px;
        font-weight: 100;
      }
    }
  }
`

const StyledTitle = styled('span')`
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 600px) {
      font-size: 10px;
      font-weight: 100;
  }
`

export {
  StyledHeader as Header,
  StyledMenuIcon as MenuIcon,
  StyledTitle as Title,
}