import { Layout, Space, notification } from 'antd';
import LoginForm from "./LoginForm";
import logo from '../../assets/logoblue2x.png';
import logob12 from '../../assets/b12logo.png';
import logohyc from '../../assets/logoHyC.png';
import background from '../../assets/bgPalacio.png';
import { Viewport, ViewportCover, Main, Logo, Footer, LoaderViewport, Loader } from './StyledLoginPage.js';
import { useEffect, useState } from 'react';
import useQueryParams from '../../../../hooks/useQueryParams';
import { AppConfig } from '../../../../helpers/config.js';
import Utils from '../../../../helpers/utils.js';

const { Content } = Layout;

const LoginPage = () => {
  let query = useQueryParams();
  const [viewportBG, setViewportBG] = useState('');

  useEffect(() => {
    loadBackground();
  }, []);

  useEffect(() => {
    let status = query?.get('status');
    if (status == 'expired') {
      notification.error({ message: 'Error', description: 'Sesión expirada', placement: 'bottomRight' });
    }
  }, [query]);

  const loadBackground = () => {
    var image = new Image();
    image.addEventListener('load', function () {
      setViewportBG('url(' + background + ')')
    });
    image.src = background;
  }

  return (
    <Content>
      {viewportBG === '' &&
        <LoaderViewport>
          <Loader src={logo} alt="logo" />
        </LoaderViewport>
      }
      {viewportBG !== '' &&
        <>
          <Viewport style={{ backgroundImage: `url(${background})` }}>
            <ViewportCover />
          </Viewport>

          <Main>
            <Logo>
              <img src={logo} alt="logo" />
            </Logo>

            <LoginForm />

            <Footer>
              <Space>
                <small>V. {AppConfig.version} {Utils.isTestEnv() ? '- TEST VERSION -' : '-'}</small>
                <small>Powered by</small>
                <img src={logob12} alt="b12" />
                <img src={logohyc} alt="hyc" />
              </Space>
            </Footer>
          </Main>
        </>}
    </Content>
  )
}

export default LoginPage;