
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import ClientesList from "./components/clientesList";
import ClientesView from "./components/clientesView";
 
const ClientesPage = () => {
  return (
    <Routes>
      <Route index element={<ClientesList />} />
      <Route path="/view/:id" element={<ClientesView />} />
    </Routes>
  );
}

export default ClientesPage;