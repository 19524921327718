import React from "react";
import { Table } from "antd";
import ImagePreview from "../../../components/ImagePreview";

const CuponesTable = props => {
  const columns = [
    {
      title: 'Imagen (500/400)', dataIndex: 'file', align: 'center',
      render: (text, record) => (
        record.assets?.length > 0 ? <ImagePreview file={record.assets[0]?.file} /> : ''
      )
    },
    { title: 'Tipo', dataIndex: 'tipo_cupon', align: 'center' },
    {
      title: 'Descuento', dataIndex: 'descuento', align: 'center',
      render: text => <span>{text}%</span>
    },
    { title: 'Validez previa', dataIndex: 'validez_previa', align: 'center' },
    { title: 'Validez posterior', dataIndex: 'validez_posterior', align: 'center' },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={props.cupones?.sort((a, b) => a.tipo_cupon - b.tipo_cupon)}
      rowKey={record => record.id}
      pagination={{ hideOnSinglePage: true, pageSize: 10 }}
      scroll={{ x: '50%' }}
    />
  );
}

export default CuponesTable;