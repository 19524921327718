import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Table, Space, Button, Col, Row, DatePicker } from "antd";
import { CloudDownloadOutlined } from '@ant-design/icons';
import ClientesApi from "../api/clientesApi";
import TableSearch from "../../../components/tableSearch";
import PageContainer from "../../../components/pageContainer";
import ViewButton from "../../../components/buttons/viewButton";
import parser from "../../../helpers/parser";
import Restrict from "../../../components/restrict";
import { Consts } from "../../../helpers/consts";

const ClientesList = props => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [fechaDesde, setFechaDesde] = useState(undefined);
  const [fechaHasta, setFechaHasta] = useState(undefined);
  const [total, setTotal] = useState(0);
  const [working, setWorking] = useState(false);
  const [exporting, setExporting] = useState(false);
  const perPage = 10;

  useEffect(() => {
    if (!loaded) {
      loadClientes(1);
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      loadClientes(1);
    }
  }, [searchText, fechaDesde, fechaHasta]);

  const columns = [
    { title: 'Dni', dataIndex: 'dni', key: 'dni' },
    { title: 'Nombre', dataIndex: 'name', key: 'name' },
    { title: 'Teléfono', dataIndex: 'telefono', key: 'telefono' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Fecha alta', dataIndex: 'fecha_alta', key: 'fecha_alta',
      render: text => parser.datetime(text)
    },
    {
      key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <ViewButton onClick={() => navigate('./view/' + record.id)} />
        </Space>
      }
    }
  ];

  const loadClientes = async (page) => {
    setWorking(true);
    try {
      const response = await ClientesApi.get(undefined, perPage, page, searchText,
        fechaDesde ? fechaDesde.set('hour', 0).set('minute', 0).set('second', 0) : fechaDesde,
        fechaHasta ? fechaHasta.set('hour', 23).set('minute', 59).set('second', 59) : fechaHasta);
      setClientes(response.data);
      setTotal(response.total);
      setLoaded(true);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);;
  }

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = 'id,dni,nombre completo,email,fecha nacimiento,telefono,localidad,codigo postal,fecha alta\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const exportData = async () => {
    setExporting(true);
    try {
      let currentPage = 1;
      let lastPage = 1;
      let data = [];
      while (currentPage <= lastPage) {
        const response = await ClientesApi.get(undefined, 5000, currentPage, searchText,
          fechaDesde ? fechaDesde.set('hour', 0).set('minute', 0).set('second', 0) : fechaDesde,
          fechaHasta ? fechaHasta.set('hour', 23).set('minute', 59).set('second', 59) : fechaHasta);
        data = data.concat(response.data.map(x => {
          return {
            id: x.id,
            dni: x.dni,
            //name: x.name,
            nombre_completo: x.nombre_completo,
            email: x.email,
            fecha_nacimiento: parser.date(x.fecha_nacimiento),
            telefono: x.telefono,
            localidad: x.localidad,
            codigo_postal: x.codigo_postal,
            fecha_alta: parser.date(x.fecha_alta),
          }
        }));
        lastPage = response.last_page;
        currentPage++;
      }
      const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = csvURL;
      link.download = `clientes.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (error) {
      console.debug(error);
    }
    setExporting(false);
  }

  return (
    <PageContainer
      title="Clientes"
      extra={
        <Restrict to={[Consts.ROLES.ADMIN]}>
          <Button
            type="primary"
            icon={<CloudDownloadOutlined />}
            onClick={exportData}
            loading={exporting}
          >
            Descargar Excel
          </Button>
        </Restrict>
      }
    >
      <Row gutter={[16, 16]}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <TableSearch
            style={{ width: '100%' }}
            placeholder="Buscar por dni, nombre, email"
            value={searchText}
            onChange={e => setSearchText(e.target.value?.toLowerCase())}
          />
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Fecha alta desde"
            format={'DD-MM-YYYY'}
            value={fechaDesde}
            onChange={value => setFechaDesde(value)}
            allowClear
          />
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Fecha alta hasta"
            format={'DD-MM-YYYY'}
            value={fechaHasta}
            onChange={value => setFechaHasta(value)}
            allowClear
          />
        </Col>
        <Col span={24}>
          <Table
            size="small"
            loading={working}
            columns={columns}
            dataSource={clientes?.sort((a, b) => a.email.localeCompare(b.email))}
            rowKey={record => record.id}
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: false,
              pageSize: 10,
              //current: currentPage,
              total: total,
              onChange: (page, pageSize) => loadClientes(page)
            }}
            scroll={{ x: '50%' }}
          />
        </Col>
      </Row>
    </PageContainer>
  );
}

export default ClientesList;