import React, { useState, useEffect } from 'react';
import { Pie } from '@ant-design/plots';
import { Spin } from 'antd';
import _ from 'lodash';
import ClientesApi from '../../clientes/api/clientesApi';

const ClientesEdadChart = props => {
  const [chartData, setChartData] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadInfo();
  }, []);

  const loadInfo = async () => {
    setWorking(true);
    try {
      const response = await ClientesApi.getClientesPorRangoEdad();
      const total = Object.values(response).reduce((a, b) => a + b, 0);

      let chartDataAux = [];
      Object.keys(response).map(key => {
        return chartDataAux.push({
          type: key,
          value: response[key],
          percentage: total > 0 ? (response[key] * 100) / total : 0
        });
      });
      setChartData(_.sortBy(chartDataAux, 'type'));
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const config = {
    appendPadding: 10,
    data: chartData,
    angleField: 'percentage',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    tooltip: {
      formatter: datum => {
        return { name: datum.type, value: chartData.find(x => x.type === datum.type).value }
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Spin spinning={working}>
    <Pie {...config} />
  </Spin>;
};

export default ClientesEdadChart;
