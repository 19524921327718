import { useState } from 'react';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import PdOHome from './home/pdoHome';
import PdOPlantas from './pdoPlantas';
import PdOPlanta from './pdoPlanta';
import PdODescCat from './pdoDescCat';
import PdODescCatList from './pdoDescCatList';
import PdODescMontoList from './pdoDescMontoList';
import PdODescMonto from './pdoDescMonto';
import PdODescArticulo from './pdoDescMonto';

const Phone = (props) => {
  const [navigation, setNavigation] = useState({
    view: 'home',
    state: {},
  });

  return (
    <DeviceFrameset
      device="iPhone X"
      color="black"
    >
      {(navigation.view === 'home' ||
        navigation.view === 'perfil' ||
        navigation.view === 'scanner' ||
        navigation.view === 'notificaciones') && (
          <PdOHome navigation={navigation} setNavigation={setNavigation} />
        )}
      {navigation.view === 'plantas' && (
        <PdOPlantas navigation={navigation} setNavigation={setNavigation} />
      )}
      {navigation.view === 'planta' && (
        <PdOPlanta navigation={navigation} setNavigation={setNavigation} />
      )}
      {navigation.view === 'descArticulo' && (
        <PdODescArticulo navigation={navigation} setNavigation={setNavigation} />
      )}
      {navigation.view === 'descCatList' && (
        <PdODescCatList navigation={navigation} setNavigation={setNavigation} />
      )}
      {navigation.view === 'descCat' && (
        <PdODescCat navigation={navigation} setNavigation={setNavigation} />
      )}
      {navigation.view === 'descMontoList' && (
        <PdODescMontoList navigation={navigation} setNavigation={setNavigation} />
      )}
      {navigation.view === 'descMonto' && (
        <PdODescMonto navigation={navigation} setNavigation={setNavigation} />
      )}
    </DeviceFrameset>
  );
};

export default Phone;
