import { Avatar, Card, Input, Spin } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import PdOHeader from "./pdoHeader";
import PlantasApi from "../../pages/plantas/api/plantasApi";
import { FaChevronRight, FaSearch } from "react-icons/fa";
import PdoNavigator from "./pdoNavigator";
import { DefaultTheme } from "../../styles/AppTheme";

const Content = styled('div')`
  flex: 1;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: scroll;
  height: 90vh;
`;

const Section = styled('div')`
  margin-bottom: 10px;
`;

const StyledCard = styled(Card)`
  cursor: pointer;
`;

const StyledTitle = styled('h4')`
  font-size: 16px;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledText = styled('p')`
  font-size: 12px;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PdOPlantas = props => {
  const [plantas, setPlantas] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadPlantas();
  }, []);

  const loadPlantas = async () => {
    setWorking(true);
    try {
      const response = await PlantasApi.get();
      setPlantas(response.sort((a, b) => a.orden - b.orden));
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/*<PdOHeader header="Mapa de Ubicaciones" />*/}
      <Content>
        <Spin spinning={working}>
          <Section>
            <Input
              placeholder="Buscar ubicación artículo"
              prefix={<FaSearch />}
              style={{ borderRadius: 5 }}
            />
          </Section>

          {plantas.map(planta => (
            <Section key={planta.id}>
              <StyledCard size="small"
                onClick={() => props.setNavigation({
                  view: 'planta',
                  state: { id: planta.id, goBack: 'plantas' }
                })}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Avatar
                    size={50}
                    style={{
                      backgroundColor: DefaultTheme.colors.black,
                      borderRadius: 5,
                      fontWeight: 'bold'
                    }}
                  >
                    {planta.iniciales}
                  </Avatar>
                  <div style={{ marginLeft: 16, flex: 1 }}>
                    <StyledTitle bold>{planta.titulo}</StyledTitle>
                    <StyledText>{planta.subtitulo}</StyledText>
                  </div>
                  <FaChevronRight />
                </div>
              </StyledCard>
            </Section>
          ))}
        </Spin>
      </Content>
      <PdoNavigator
        navigation={props.navigation}
        setNavigation={props.setNavigation}
      />
    </div>
  )
}

export default PdOPlantas;