import React from "react";
import { Table } from "antd";
import parser from "../../../helpers/parser";

const CuponesUsadosTable = props => {
  const columns = [
    { title: 'Tipo', dataIndex: 'tipocupon', align: 'center' },
    { title: 'Código', dataIndex: 'codigo_cupon', align: 'center' },
    {
      title: 'Descuento', dataIndex: 'porcentaje', align: 'center',
      render: text => <span>{text}%</span>
    },
    { title: 'Tipo Cbte', dataIndex: 'tipo_comprobante', align: 'center' },
    { title: 'Nro Cbte', dataIndex: 'numero_comprobante', align: 'center' },
    {
      title: 'Usado el', dataIndex: 'usado_at', align: 'center',
      render: text => parser.datetime(text)
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={props.cupones?.sort((a, b) => a.usado_at - b.usado_at)}
      rowKey={record => record.id}
      pagination={{ hideOnSinglePage: true, pageSize: 10 }}
      scroll={{ x: '50%' }}
    />
  );
}

export default CuponesUsadosTable;