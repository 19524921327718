import styled from "styled-components";
import { Consts } from "../../../../helpers/consts";
import { DefaultTheme } from "../../../../styles/AppTheme";

const Container = styled('div')`
  border-radius: 10px;
  border: 1px solid black;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
`;

const CuponItem = props => {
  return (
    <Container>
      <div style={{color: DefaultTheme.colors.orange}}>{props.icon}</div>
      <div style={{ flex: 1 }}>
        <p>
          {props.cupon?.tipo?.titulo ? props.cupon?.tipo?.titulo : Consts.Cupones[props.cupon?.tipo?.tipo_cupon]?.TITULO}
        </p>
        <p>
          {props.cupon?.porcentaje}% de descuento
        </p>
      </div>
    </Container>
  )
}

export default CuponItem;