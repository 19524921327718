import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { Button, Form, Card, Input, notification, Select, Spin, DatePicker, AutoComplete } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import { FormConfig } from "../../../helpers/config";
import UsuariosApi from "../api/usuariosApi";
import { PageHeader } from "@ant-design/pro-layout";
import moment from "moment";
import dayjs from 'dayjs';
import { Consts } from "../../../helpers/consts";

const FormItem = Form.Item;
const Option = Select.Option;

const UsuariosForm = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [usuario, setUsuario] = useState(undefined);
  const [roles] = useState([
    { label: Consts.ROLES.ADMIN, value: Consts.ROLES.ADMIN },
    { label: Consts.ROLES.GERENTE, value: Consts.ROLES.GERENTE },
    { label: Consts.ROLES.DISEÑADOR, value: Consts.ROLES.DISEÑADOR }
  ]);
  const [localidades, setLocalidades] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      loadUsuario(params.id);
    }
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [usuario]);

  const loadUsuario = async id => {
    setWorking(true);
    try {
      const response = await UsuariosApi.get(id);
      setUsuario(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const searchLocalidades = async search => {
    try {
      const response = await UsuariosApi.getLocalidades(search);
      setLocalidades(response.map(loc => {
        return {
          ...loc,
          label: loc.nombre + ' - ' + loc.provincia?.abreviat,
          value: loc.codpostal + loc.nombre
        }
      }));
    }
    catch (error) {
      console.debug(error);
    }
  }

  const onFinish = async values => {
    if (values.password !== values.newpassword) {
      notification.error({ message: 'Error', description: 'Los password deben ser iguales', placement: 'bottomRight' });
      return;
    }

    setWorking(true);
    try {
      let data = {
        id: usuario?.id,
        name: values.nombre_completo,
        ...values,
      }

      await UsuariosApi.save(data);
      notification.success({ message: 'Exito', description: 'Usuario guardado correctamente', placement: 'bottomRight' });
      navigate(-1);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  };

  return (
    <Card>
      <PageHeader
        title={`${usuario && usuario.id ? 'Editar usuario' : 'Nuevo usuario'}`}
        /*subTitle="Formulario"*/
        onBack={() => navigate(-1)}
      />
      <Spin spinning={working}>
        <Card>
          <Form
            {...FormConfig.DefaultLayout}
            form={form}
            name="formulario"
            onFinish={onFinish}
            initialValues={{
              ...usuario,
              fecha_nacimiento: usuario?.fecha_nacimiento ? dayjs(usuario.fecha_nacimiento) : null,
              fecha_alta: usuario?.fecha_alta ? dayjs(usuario.fecha_alta) : dayjs(),
            }}
            scrollToFirstError
            autoComplete="off"
          >
            <FormItem name="dni" label="Dni" rules={[...FormConfig.DefaultRules]}>
              <Input />
            </FormItem>

            <FormItem name="nombre_completo" label="Nombre completo" rules={[...FormConfig.DefaultRules]}>
              <Input />
            </FormItem>

            <FormItem name="localidad" label="Localidad" rules={[...FormConfig.DefaultRules]}>
              <AutoComplete
                options={localidades}
                style={{ width: '100%' }}
                onSelect={(value, loc) => {
                  if (value) {
                    form.setFieldsValue({
                      localidad: loc.nombre,
                      codigo_postal: loc.codpostal.toString().substring(0, 4)
                    });
                  }
                }}
                onSearch={text => searchLocalidades(text)}
              />
            </FormItem>

            <FormItem name="codigo_postal" label="Código Postal" rules={[...FormConfig.DefaultRules]}>
              <Input />
            </FormItem>

            <FormItem name="telefono" label="Teléfono" rules={[...FormConfig.DefaultRules]}>
              <Input />
            </FormItem>

            <FormItem name="fecha_nacimiento" label="Fecha nacimiento" rules={[...FormConfig.DefaultRules]}>
              <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }}
                disabledDate={currentDate => currentDate.isAfter(moment().utc())}
              />
            </FormItem>

            <FormItem name="fecha_alta" label="Fecha alta" rules={[...FormConfig.DefaultRules]}>
              <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} disabled />
            </FormItem>

            <FormItem name="email" label="Email"
              rules={[
                ...FormConfig.DefaultRules,
                { type: 'email', message: 'Por favor ingrese un email valido' }
              ]}
            >
              <Input />
            </FormItem>

            <FormItem name="role" label="Rol" rules={FormConfig.DefaultRules}>
              <Select showSearch filterOption={FormConfig.DefaultSelectFilter} autoComplete="none">
                {roles.map(rol =>
                  <Option key={rol.value} value={rol.value}>{rol.label}</Option>
                )}
              </Select>
            </FormItem>

            {!usuario?.id &&
              <>
                <FormItem name="password" label="Password"
                  rules={[
                    ...FormConfig.DefaultRules,
                    { min: 6, message: 'El password debe tener al menos 6 caracteres' }
                  ]}
                >
                  <Input.Password />
                </FormItem>

                <FormItem name="newpassword" label="Repetir password"
                  rules={[
                    ...FormConfig.DefaultRules,
                    { min: 6, message: 'El password debe tener al menos 6 caracteres' }
                  ]}
                >
                  <Input.Password />
                </FormItem>
              </>
            }

            <FormItem wrapperCol={{ span: 16, offset: 8 }}>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Guardar</Button>
            </FormItem>

          </Form>
        </Card>
      </Spin>
    </Card>
  );
}

export default UsuariosForm;

