import React, { useEffect, useState } from "react";
import DescuentosClubApi from "../api/descuentosClubApi";
import DescuentosTable from "./descuentosClubTable";
import PageContainer from "../../../components/pageContainer";
import TableSearch from "../../../components/tableSearch";
import { Button, Col, DatePicker, Row } from "antd";
import { CloudDownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import parser from "../../../helpers/parser";
import Restrict from "../../../components/restrict";
import { Consts } from "../../../helpers/consts";

const DescuentosClubList = props => {
  const [descuentos, setDescuentos] = useState([]);
  const [descuentosFiltradas, setDescuentosFiltradas] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [fechaDesde, setFechaDesde] = useState(undefined);
  const [fechaHasta, setFechaHasta] = useState(undefined);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadDescuentos();
  }, []);

  useEffect(() => {
    filterDescuentos();
  }, [searchText, fechaDesde, fechaHasta]);

  const loadDescuentos = async () => {
    setWorking(true);
    try {
      const response = await DescuentosClubApi.get();
      setDescuentos(response);
      setDescuentosFiltradas(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const filterDescuentos = () => {
    let descuentosAux = [...descuentos];
    if (searchText != '') {
      descuentosAux = descuentosAux.filter(x =>
        x.descuento.toLowerCase().indexOf(searchText) > -1 ||
        x.grupo?.descripcion.toLowerCase().indexOf(searchText) > -1 ||
        x.rubro?.descripcion.toLowerCase().indexOf(searchText) > -1 ||
        x.sub_rubro?.descripcion.toLowerCase().indexOf(searchText) > -1
      )
    }
    if (fechaDesde) {
      descuentosAux = descuentosAux.filter(x =>
        dayjs(x.fecha_desde, 'YYYY-MM-DD').diff(fechaDesde, 'day') >= 0
      )
    }
    if (fechaHasta) {
      descuentosAux = descuentosAux.filter(x =>
        dayjs(x.fecha_hasta, 'YYYY-MM-DD').diff(fechaHasta, 'day') <= 0
      )
    }
    setDescuentosFiltradas(descuentosAux);
  }

  return (
    <PageContainer
      title="Descuentos globales"
      extra={
        <Restrict to={[Consts.ROLES.ADMIN]}>
          <CSVLink
            data={descuentosFiltradas.map(x => {
              return {
                id: x.id,
                sector: x.grupo?.descripcion,
                rubro: x.rubro?.descripcion,
                subrubro: x.sub_rubro?.descripcion,
                titulo: x.titulo,
                descripcion: x.descripcion,
                extra: x.extra_1,
                descuento: x.descuento + '%',
                numero_lista_precios: x.numero_lista_precios,
                fecha_desde: parser.date(x.fecha_desde),
                fecha_hasta: parser.date(x.fecha_hasta),
                solo_efectivo: x.solo_efectivo == true ? 'SI' : 'NO',
                creado_el: parser.datetime(x.create_at),
                actualizado_el: parser.date(x.updated_at),
              }
            })}
            filename="descuentos"
          >
            <Button type="primary" icon={<CloudDownloadOutlined />}>Descargar Excel</Button>
          </CSVLink>
        </Restrict>
      }
    >
      <Row gutter={[16, 16]}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <TableSearch
            style={{ width: '100%' }}
            placeholder="Buscar por descuento, sector, rubro, subrubro"
            value={searchText}
            onChange={e => setSearchText(e.target.value?.toLowerCase())}
          />
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Fecha desde"
            format={'DD-MM-YYYY'}
            value={fechaDesde}
            onChange={value => setFechaDesde(value)}
            allowClear
          />
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Fecha hasta"
            format={'DD-MM-YYYY'}
            value={fechaHasta}
            onChange={value => setFechaHasta(value)}
            allowClear
          />
        </Col>
        <Col span={24}>
          <DescuentosTable
            descuentos={descuentosFiltradas}
            working={working}
          />
        </Col>
      </Row>
    </PageContainer>
  );
}

export default DescuentosClubList;