import React, { useContext } from 'react';
import AuthHelper from '../helpers/authHelper';

const Restrict = ({ to, fallback, children }) => {
  let user = AuthHelper.getUser();
  
  if (to.includes(user?.role)) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
};

export default Restrict;