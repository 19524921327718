import React, { useState } from "react";
import { Space, Table, Tag } from "antd";
import ParametrosDetail from "./parametrosDetail";
import ViewButton from "../../../../components/buttons/viewButton";
import EditButton from "../../../../components/buttons/editButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Consts } from "../../../../helpers/consts";

const ParametrosTable = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRubro, setSelectedRubro] = useState(undefined);
  const [showRubroDetail, setShowRubroDetail] = useState(false);
  const [working, setWorking] = useState(false);

  const columns = [
    { title: 'Clave', dataIndex: 'clave', align: 'center' },
    {
      title: 'Valor', dataIndex: 'valor', align: 'center',
      render: (text, record) => record.clave === Consts.PARAMETROS.GEOLOCALIZACION
        ? <Tag color={text === "True" ? 'success' : 'error'}>{text === "True" ? 'Habilitado' : 'Deshabilitado'}</Tag>
        : text
    },
    { title: 'Descripción', dataIndex: 'descripcion', },
    {
      title: '', dataIndex: 'acciones', width: '10%', align: 'center',
      render: (text, record) => <Space>
        <ViewButton onClick={() => { setSelectedRubro(record); setShowRubroDetail(true) }} />
        <EditButton onClick={() => navigate(location.pathname + '/edit/' + record.id)} />
      </Space>
    },
  ];

  return (
    <>
      <Table
        size="small"
        loading={working}
        columns={columns}
        dataSource={props.parametros}
        rowKey={record => record.id}
        pagination={{ hideOnSinglePage: true, pageSize: 10 }}
        scroll={{ x: '50%' }}
      />
      <ParametrosDetail
        open={showRubroDetail}
        onClose={() => setShowRubroDetail(false)}
        parametro={selectedRubro}
      />
    </>
  );
}

export default ParametrosTable;