import React, { useEffect, useState } from "react";
import RubrosApi from "../api/rubrosApi";
import PageContainer from "../../../../components/pageContainer";
import TableSearch from "../../../../components/tableSearch";
import { Col, Row, Spin } from "antd";
import RubrosTable from "./rubrosTable";
import { useNavigate } from "react-router-dom";

const RubrosList = props => {
  const navigate = useNavigate();
  const [rubros, setRubros] = useState([]);
  const [rubrosFiltrados, setRubrosFiltrados] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadRubros();
  }, []);

  useEffect(() => {
    filterRubros();
  }, [searchText]);

  const loadRubros = async () => {
    setWorking(true);
    try {
      const response = await RubrosApi.get();
      setRubros(response);
      setRubrosFiltrados(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const filterRubros = () => {
    let rubrosAux = [...rubros];
    if (searchText != '') {
      rubrosAux = rubrosAux.filter(x =>
        x.codigo_rubro.toString().toLowerCase().indexOf(searchText) > -1 ||
        x.codigo_subrubro.toString().toLowerCase().indexOf(searchText) > -1 ||
        x.descripcion.toLowerCase().indexOf(searchText) > -1
      )
    }
    setRubrosFiltrados(rubrosAux);
  }

  return (
    <PageContainer
      title="Rubros"
      onBack={() => navigate(-1)}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <TableSearch
              style={{ width: '100%' }}
              placeholder="Buscar por rubro, subrubro, descripción"
              value={searchText}
              onChange={e => setSearchText(e.target.value.toLowerCase())}
            />
          </Col>
          <Col span={24}>
            <RubrosTable
              rubros={rubrosFiltrados}
            />
          </Col>
        </Row>
      </Spin>
    </PageContainer>
  );
}

export default RubrosList;