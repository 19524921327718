import React from "react";
import { Button, Tooltip } from "antd";
import { EditOutlined } from '@ant-design/icons';

const EditButton = props => {
  return (
    <Tooltip
      title={props.btnTitle ? props.btnTitle : 'Editar'}
      placement="top">
      <Button
        shape="circle"
        size="small"
        type={props.btnType}
        icon={props.icon ? props.icon : <EditOutlined />}
        onClick={e => {
          e.stopPropagation();
          props.onClick();
        }}
      />
    </Tooltip>
  );
}

export default EditButton;