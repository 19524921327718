import React, { useEffect, useState } from "react";
import { Spin, Row, Col, Tabs, Descriptions, Image } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import MediaTable from "../../../components/media/mediaTable";
import PageContainer from "../../../components/pageContainer";
import UploadButton from "../../../components/buttons/uploadButton";
import EditButton from "../../../components/buttons/editButton";
import BannersForm from "./bannersForm";
import BannersApi from "../api/bannersApi";
import ImagePreview from "../../../components/ImagePreview";

const { TabPane } = Tabs;
const DescItem = Descriptions.Item;

const BannersView = props => {
  const navigate = useNavigate();
  const params = useParams();
  const [banner, setBanner] = useState(undefined);
  const [showBannerForm, setShowBannerForm] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadBanner();
  }, []);

  const loadBanner = async () => {
    setWorking(true);
    try {
      const response = await BannersApi.get(params.id);
      setBanner(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title={`Banner: ${banner?.titulo ? banner?.titulo : ''}`}
      onBack={() => navigate(-1)}
      extra={<EditButton onClick={() => setShowBannerForm(true)} />}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Descriptions size="small" column={2} bordered>
              <DescItem label="Título" span={2}><b>{banner?.titulo}</b></DescItem>
              <DescItem label="Descripción" span={2}><b>{banner?.extra_1}</b></DescItem>
              <DescItem label="Url" span={2}><b>{banner?.url}</b></DescItem>
              <DescItem label="Splash" span={2}><b>{banner?.splash == true ? 'SI' : 'NO'}</b></DescItem>
              <DescItem label="Splash - Segundos en pantalla" span={2}><b>{banner?.active_seconds}</b></DescItem>
              <DescItem label="Publicada" span={2}><b>{banner?.publicada == true ? 'SI' : 'NO'}</b></DescItem>
              <DescItem label="Imagen" span={2}><ImagePreview file={banner?.assets[0]?.file} /></DescItem>
            </Descriptions>
          </Col>
        </Row>
      </Spin>

      <BannersForm
        open={showBannerForm}
        onClose={() => setShowBannerForm(false)}
        banner={banner}
        onUpload={loadBanner}
        saveBanner={data => BannersApi.save(data)}
      />
    </PageContainer>
  );
}

export default BannersView;

