import { useState } from 'react';
import { Row, Col, Space } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import AuthHelper from '../../../helpers/authHelper';
import { AppConfig } from '../../../helpers/config';
import { Header, MenuIcon, Title } from './StyledAppHeader';
import Utils from '../../../helpers/utils';
import UserButton from '../../../components/buttons/userButton';

const AppHeader = props => {
  const [user] = useState(AuthHelper.getUser());

  return (
    <Header test={Utils.isTestEnv()}>
      <Row>
        <Col span={12}>
          {!props.hideMenuIcon &&
            <MenuIcon>
              {props.collapsed
                ? <CaretRightOutlined onClick={() => props.setCollapsed(false)} />
                : <CaretLeftOutlined onClick={() => props.setCollapsed(true)} />}
            </MenuIcon>}

          <Title>
            {`${AppConfig.siteName} ${(Utils.isTestEnv() ? ' - TEST' : '')}`}
          </Title>
        </Col>

        <Col span={12} style={{ textAlign: 'right' }}>
          <div style={{
            display: 'flex', flexDirection: 'row', lineHeight: 'normal',
            justifyContent: 'flex-end', alignItems: 'center', height: '100%',
            gap: 24
          }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div><small>Hola {user?.name}!</small></div>
              <small>{user?.email}</small>
            </div>
            <UserButton />
          </div>
        </Col>
      </Row>
    </Header >
  );
}

export default AppHeader;
