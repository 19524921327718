import React, { useEffect, useState } from "react";
import ParametrosApi from "../api/parametrosApi";
import PageContainer from "../../../../components/pageContainer";
import TableSearch from "../../../../components/tableSearch";
import { Col, Row, Spin } from "antd";
import ParametrosTable from "./parametrosTable";
import { useNavigate } from "react-router-dom";

const ParametrosList = props => {
  const navigate = useNavigate();
  const [parametros, setParametros] = useState([]);
  const [parametrosFiltrados, setParametrosFiltrados] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadParametros();
  }, []);

  useEffect(() => {
    filterParametros();
  }, [searchText]);

  const loadParametros = async () => {
    setWorking(true);
    try {
      const response = await ParametrosApi.get();;
      setParametros(response);
      setParametrosFiltrados(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false)
  }

  const filterParametros = () => {
    let parametrosAux = [...parametros];
    if (searchText != '') {
      parametrosAux = parametrosAux.filter(x =>
        x.clave.toString().toLowerCase().indexOf(searchText) > -1 ||
        x.valor.toString().toLowerCase().indexOf(searchText) > -1 ||
        x.descripcion.toLowerCase().indexOf(searchText) > -1
      )
    }
    setParametrosFiltrados(parametrosAux);
  }

  return (
    <PageContainer
      title="Parametros"
      onBack={() => navigate(-1)}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <TableSearch
              style={{ width: '100%' }}
              placeholder="Buscar por clave, valor, descripción"
              value={searchText}
              onChange={e => setSearchText(e.target.value.toLowerCase())}
            />
          </Col>
          <Col span={24}>
            <ParametrosTable
              parametros={parametrosFiltrados}
            />
          </Col>
        </Row>
      </Spin>
    </PageContainer>
  );
}

export default ParametrosList;