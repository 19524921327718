import React, { useEffect, useState } from "react";
import BannersApi from "../api/bannersApi";
import PageContainer from "../../../components/pageContainer";
import { Button, Spin } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import BannersTable from "./bannersTable";
import BannersForm from "./bannersForm";

const BannersList = props => {
  const [banners, setBanners] = useState([]);
  const [bannersFiltradas, setBannersFiltradas] = useState([]);
  const [showBannersForm, setShowBannersForm] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadBanners();
  }, []);

  const loadBanners = async () => {
    setWorking(true);
    try {
      const response = await BannersApi.get();
      setBanners(response);
      setBannersFiltradas(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title="Banners"
      extra={[
        <Button key="1" type="primary" icon={<PlusOutlined />}
          onClick={() => setShowBannersForm(true)}>
          Nuevo
        </Button>
      ]}
    >
      <Spin spinning={working}>
        <BannersTable
          banners={bannersFiltradas}
          loadBanners={loadBanners}
          setWorking={setWorking}
        />
      </Spin>

      <BannersForm
        open={showBannersForm}
        onClose={() => setShowBannersForm(false)}
        saveBanner={data => BannersApi.save(data)}
        onUpload={() => loadBanners()}
        filetypes={'image/jpeg, image/jpg, image/png, image/gif'}
        orden={banners.length || 0}
      />
    </PageContainer>
  );
}

export default BannersList;