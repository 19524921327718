import styled from "styled-components";
import { DefaultTheme } from "../../../../styles/AppTheme";

const Container = styled('div')`
  border-radius: 10px;
  border: 1px solid black;
  //padding: 0px 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const ContainerRight = styled('div')`
  width: 100px;
  color: white;
  background-color: ${props => DefaultTheme.colors.lightBlue};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
`;

const DescCatItem = props => {
  return (
    <Container onClick={props.onClick}>
      <div style={{ flex: 1, padding: '10px', display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontSize: 10, fontWeight: 'bold' }}>{props.descuento?.grupo?.descripcion}</span>
        <span style={{ fontSize: 10 }}>{props.descuento?.rubro?.descripcion}</span>
        <span style={{ fontSize: 10 }}>{props.descuento?.sub_rubro?.descripcion}</span>
      </div>
      <ContainerRight>
        <span style={{ fontSize: 24, fontWeight: 'bold' }}>{props.descuento?.descuento}%</span>
        <span style={{ fontSize: 10 }}>DE DESCUENTO</span>
      </ContainerRight>
    </Container>
  )
}

export default DescCatItem;