
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import GruposList from "./components/gruposList";
 
const GruposPage = () => {
  return (
    <Routes>
      <Route index element={<GruposList />} />
    </Routes>
  );
}

export default GruposPage;