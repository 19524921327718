import React, { useState } from "react";
import { Button, Form, Input, notification, Row, Spin } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import { FormConfig } from "../../../helpers/config";
import NotificacionApi from "../api/notificacionesApi";
import PageContainer from "../../../components/pageContainer";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;
const FormItem = Form.Item;

const NotificacionForm = props => {
  const navigate = useNavigate();
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async values => {
    setWorking(true);
    try {
      let data = {
        ...props.notificacion,
        ...values,
      }

      await NotificacionApi.save(data);
      notification.success({ message: 'Exito', description: 'Notificación creada correctamente', placement: 'bottomRight' });
      navigate(-1);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  };

  return (
    <PageContainer
      title="Nueva notificación"
      onBack={() => navigate(-1)}
    >
      <Spin spinning={working}>
        <Form
          {...FormConfig.DefaultLayout}
          form={form}
          name="formulario"
          onFinish={onFinish}
          initialValues={{ ...props.notificacion }}
          scrollToFirstError
        >
          <FormItem name="title" label="Titulo" rules={FormConfig.DefaultRules}>
            <Input maxLength={255} showCount />
          </FormItem>

          <FormItem name="body" label="Mensaje" rules={FormConfig.DefaultRules}>
            <TextArea rows={6} maxLength={255} showCount />
          </FormItem>

          <FormItem wrapperCol={{ span: 24 }}>
            <Row justify={'end'}>
              <Button
                key="ok"
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              //onClick={() => form.submit()}
              >
                Guardar
              </Button>
            </Row>
          </FormItem>
        </Form>
      </Spin>
    </PageContainer>
  );
}

export default NotificacionForm;

