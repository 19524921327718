import React, { useState } from "react";
import { Space, Table } from "antd";
import { useNavigate } from "react-router-dom";
import parser from "../../../helpers/parser";
import ViewButton from "../../../components/buttons/viewButton";
import ImagePreview from "../../../components/ImagePreview";

const DescuentosArticuloTable = props => {
  const navigate = useNavigate();
  const [working, setWorking] = useState(false);

  const columns = [
    {
      title: 'Imagen (500x400)', dataIndex: 'file', align: 'center',
      render: (text, record) => (
        record.assets.length > 0 ? <ImagePreview file={record.assets[0]?.file} /> : ''
      )
    },
    {
      title: 'Nro Art.', dataIndex: 'nroart', align: 'center',
    },
    {
      title: 'Articulo', dataIndex: ['articulo', 'descripcion'], align: 'center',
    },
    {
      title: 'Precio base', dataIndex: ['articulo', 'precio1f'], align: 'center',
      render: text => parser.currency(text)
    },
    {
      title: 'Precio oferta', dataIndex: 'preciooferta', align: 'center',
      render: text => parser.currency(text)
    },
    {
      title: 'Stock disponible', dataIndex: ['articulo', 'stockact'], align: 'center',
      render: text => parseInt(text)
    },
    {
      title: 'Inicio', dataIndex: 'fechadesde', align: 'center',
      render: text => parser.date(text)
    },
    {
      title: 'Vencimiento', dataIndex: 'fechavenc', align: 'center',
      render: text => parser.date(text)
    },
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        <Space>
          <ViewButton onClick={() => navigate('./view/' + record.nrooferta)} />
        </Space>
      )
    },
  ];

  return (
    <Table
      size="small"
      loading={working}
      columns={columns}
      dataSource={props.descuentos}
      rowKey={record => record.nrooferta}
      pagination={{ hideOnSinglePage: true, pageSize: 10 }}
      scroll={{ x: '50%' }}
    />
  );
}

export default DescuentosArticuloTable;