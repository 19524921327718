import Fetcher from "../../../helpers/fetcher";

const DescuentosClubApi = {
  get: id => {
    let url = '/descuentos-club/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  getVigentes: () => {
    let url = '/descuentos-club/vigentes';
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  save: data => {
    if(!data.id){
      return Fetcher
        .post('/descuentos-club', data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
    else{
      return Fetcher
        .put('/descuentos-club/' + data.id, data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
  },
  /*delete: id => {
    return Fetcher
      .delete('/descuentos-club/' + id, {})
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },*/
  uploadFile: (formdata) => {
    formdata.append('entity', 'CLUB');

    return Fetcher
      .post('/assets', formdata)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  deleteFile: (id) => {
    return Fetcher
      .delete('/assets/' + id)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
}

export default DescuentosClubApi;