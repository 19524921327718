import React, { FC, useEffect, useState } from "react";
import { Image, Space, Switch, Table } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import BannersApi from "../api/bannersApi";
import DeleteButton from "../../../components/buttons/deleteButton";
import ImagePreview from "../../../components/ImagePreview";
import ViewButton from "../../../components/buttons/viewButton";
import { useNavigate } from "react-router-dom";
import { TbCheck } from "react-icons/tb";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const BannersTable = props => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    if (props.banners) {
      setBanners(props.banners.sort((a, b) => a.orden - b.orden));
    }
  }, [props.banners]);

  const columns = [
    { key: 'sort', width: 50, align: 'center' },
    //{ title: 'id', dataIndex: 'id', width: 50, align: 'center' },
    {
      title: 'Imagen (350x350) / Splash (300x500)', dataIndex: 'file', align: 'center',
      render: (text, record) => record.assets.length > 0
        ? <ImagePreview file={record.assets[0]?.file} />
        : ''
    },
    { title: 'Titulo', dataIndex: 'titulo', },
    {
      title: 'Splash', dataIndex: 'splash', align: 'center', width: '15%',
      render: (text, record) => text === true ? <TbCheck /> : ''
    },
    {
      title: 'Publicada', dataIndex: 'publicada', align: 'center', width: '15%',
      render: (text, record) => <Switch checked={text} onChange={value => changePublicada(value, record.id)} />
    },
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        <Space>
          <ViewButton onClick={() => navigate('./view/' + record.id)} />
          <DeleteButton onConfirm={() => deleteBanner(record.id)} />
        </Space>
      )
    },
  ]

  const changePublicada = async (checked, id) => {
    props.setWorking(true);
    try {
      if (checked == true) {
        await BannersApi.publicar(id);
      }
      else {
        await BannersApi.despublicar(id);
      }
      await props.loadBanners();
    }
    catch (error) {
      console.debug(error);
      props.setWorking(false);
    }
  }

  const deleteBanner = async id => {
    props.setWorking(true);
    try {
      await BannersApi.delete(id)
      await props.loadBanners();
    }
    catch (error) {
      console.debug(error);
    }
    props.setWorking(false);
  }

  const onDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id) {
      props.setWorking(true);
      try {
        let bannersAux = [...banners];
        const activeIndex = bannersAux.findIndex(i => i.id === active.id);
        const overIndex = bannersAux.findIndex(i => i.id === over?.id);
        bannersAux = arrayMove(bannersAux, activeIndex, overIndex);
        for (let i = 0; i < bannersAux.length; i++) {
          if (bannersAux[i].orden != i) {
            await BannersApi.cambiarOrden(bannersAux[i].id, i);
          }
        }
        await props.loadBanners();
      }
      catch (error) {
        console.debug(error);
      }
      props.setWorking(false);
    }
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={banners.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          rowKey="id"
          size="small"
          columns={columns}
          dataSource={banners}
          pagination={{ pageSize: 10, showSizeChanger: false, hideOnSinglePage: true }}
          components={{
            body: {
              row: Row,
            },
          }}
          scroll={{ x: '50%' }}
        />

      </SortableContext>
    </DndContext>
  )
}

export default BannersTable;
