import React from "react";
import { Button, Tooltip } from "antd";
import { ReloadOutlined } from '@ant-design/icons';

const ReloadButton = props => {
  return (
    <Tooltip
      title={props.btnTitle ? props.btnTitle : 'Refrescar'}
      placement="top">
      <Button
        shape="circle"
        size="small"
        type={props.btnType}
        icon={props.icon ? props.icon : <ReloadOutlined />}
        onClick={e => {
          e.stopPropagation();
          props.onClick();
        }}
      />
    </Tooltip>
  );
}

export default ReloadButton;