import Fetcher from "../../../helpers/fetcher";

const NotificacionesApi = {
  get: id => {
    let url = 'notificaciones/';
    if (id) { url += id; }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  save: data => {
    return Fetcher
      .post('notificaciones', data)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  cancel: id => {
    return Fetcher
      .post(`notificaciones/${id}/cancelar`)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  /*delete: id => {
    return Fetcher
      .delete('notificaciones/' + id, {})
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },*/
}

export default NotificacionesApi;