
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import NotificacionesForm from "./components/notificacionesForm";
import NotificacionesList from "./components/notificacionesList";
import NotificacionesView from "./components/notificacionesView";
 
const NotificacionesPage = () => {
  return (
    <Routes>
      <Route index element={<NotificacionesList />} />
      {/*<Route path="/new" element={<NotificacionesForm />} />*/}
      <Route path="/new" element={<NotificacionesForm />} />
      <Route path="/view/:id" element={<NotificacionesView />} />
    </Routes>
  );
}

export default NotificacionesPage;