import React, { useState } from "react";
import { Space, Table } from "antd";
import { useNavigate } from "react-router-dom";
import parser from "../../../helpers/parser";
import ViewButton from "../../../components/buttons/viewButton";
import ImagePreview from "../../../components/ImagePreview";

const DescuentosMontoTable = props => {
  const navigate = useNavigate();
  const [working, setWorking] = useState(false);

  const columns = [
    {
      title: 'Imagen (500x400)', dataIndex: 'file', align: 'center',
      render: (text, record) => (
        record.assets.length > 0 ? <ImagePreview file={record.assets[0]?.file} /> : ''
      )
    },
    {
      title: 'Descuento', dataIndex: 'descuento', align: 'center',
      render: text => <span>{text}%</span>
    },
    {
      title: 'Monto desde', dataIndex: 'monto_desde', align: 'center',
      render: text => parser.currency(text)
    },
    {
      title: 'Solo efectivo', dataIndex: 'solo_efectivo', align: 'center',
      render: text => <span>{text == true ? 'SI' : 'NO'}</span>
    },
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        <Space>
          <ViewButton onClick={() => navigate('./view/' + record.id)} />
        </Space>
      )
    },
  ];

  return (
    <Table
      size="small"
      loading={working}
      columns={columns}
      dataSource={props.descuentos}
      rowKey={record => record.id}
      pagination={{ hideOnSinglePage: true, pageSize: 10 }}
      scroll={{ x: '50%' }}
    />
  );
}

export default DescuentosMontoTable;