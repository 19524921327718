import { useState } from "react";
import { Form, Input, Button, Row, Col, Alert } from "antd";
import ForgotPassApi from '../api/ForgotPassApi';
import { FormConfig } from "../../../helpers/config";
import parser from "../../../helpers/parser";

const FormItem = Form.Item;

const ForgotPassForm = props => {
  const [form] = Form.useForm();
  const [respuesta, setRespuesta] = useState(undefined);
  const [working, setWorking] = useState(false);

  const onFinish = async values => {
    setWorking(true);
    try {
      const values = await form.validateFields();

      try {
        await ForgotPassApi.changePassword(props.token, values.email, values.dni, values.password);
        setRespuesta({ type: 'success', message: 'Contraseña modificada correctamente' });
      }
      catch (error) {
        setRespuesta({ type: 'error', message: parser.parseError(error) });
      }
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  };

  return (
    <Row gutter={[16, 16]}>
      {respuesta &&
        <Col span={24}>
          <Alert type={respuesta.type} message={respuesta.message} />
        </Col>}
      <Col span={24}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <FormItem label="Email" name="email"
            rules={[
              ...FormConfig.DefaultRules,
              { type: 'email', message: 'Por favor ingrese un email válido' }
            ]}
          >
            <Input />
          </FormItem>

          <FormItem label="Dni" name="dni" rules={FormConfig.DefaultRules}>
            <Input />
          </FormItem>

          <FormItem label="Contraseña" name="password" rules={FormConfig.DefaultRules}>
            <Input.Password />
          </FormItem>

          <FormItem label="Repetir Contraseña" name="repassword"
            dependencies={['password']}
            rules={[...FormConfig.DefaultRules,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Las contraseñas no coinciden'));
              },
            }),
            ]}
          >
            <Input.Password />
          </FormItem>

          <FormItem {...{ wrapperCol: { span: 24 } }} style={{ paddingTop: 20 }}>
            <Button block type="primary" htmlType="submit" loading={working}>Modificar contraseña</Button>
          </FormItem>
        </Form>
      </Col>
    </Row>
  );
};

export default ForgotPassForm;
