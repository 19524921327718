import React, { useEffect, useState } from "react";
import CuponesApi from "../api/cuponesApi";
import PageContainer from "../../../components/pageContainer";
import { Button, Col, Row } from "antd";
import { CloudDownloadOutlined } from '@ant-design/icons';
import CuponesTable from "./cuponesTable";
import { CSVLink } from "react-csv";
import parser from "../../../helpers/parser";
import Restrict from "../../../components/restrict";
import { Consts } from "../../../helpers/consts";

const CuponesList = props => {
  const [cupones, setCupones] = useState([]);
  const [cuponesFiltradas, setCuponesFiltradas] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadCupones();
  }, []);

  useEffect(() => {
    filterCupones();
  }, [searchText]);

  const loadCupones = async () => {
    setWorking(true);
    try {
      const response = await CuponesApi.get();
      setCupones(response);
      setCuponesFiltradas(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const filterCupones = () => {
    let cuponesAux = [...cupones];
    if (searchText != '') {
      cuponesAux = cuponesAux.filter(x =>
        x.tipo_cupon.indexOf(searchText) > -1 ||
        x.titulo.indexOf(searchText) > -1 ||
        x.descuento.indexOf(searchText) > -1
      )
    }
    setCuponesFiltradas(cuponesAux);
  }

  return (
    <PageContainer
      title="Cupones"
      extra={
        <Restrict to={[Consts.ROLES.ADMIN]}>
          <CSVLink
            data={cuponesFiltradas.map(x => {
              return {
                id: x.id,
                tipo_cupon: x.tipo_cupon,
                titulo: x.titulo,
                descripcion: x.descripcion,
                descuento: x.descuento + '%',
                validez_previa: x.validez_previa,
                validez_posterior: x.validez_posterior,
                creado_el: parser.datetime(x.create_at),
                actualizado_el: parser.date(x.updated_at),
              }
            })}
            filename="cupones"
          >
            <Button type="primary" icon={<CloudDownloadOutlined />}>Descargar Excel</Button>
          </CSVLink>
        </Restrict>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <CuponesTable
            cupones={cuponesFiltradas}
            working={working}
          />
        </Col>
      </Row>
    </PageContainer>
  );
}

export default CuponesList;