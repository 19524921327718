import { Button } from "antd";
import { TbSquareArrowRight } from "react-icons/tb";
import { DefaultTheme } from "../../../../styles/AppTheme";

const MoreButton = props => {
  return (
    <Button
      type="default"
      block
      icon={<TbSquareArrowRight />}
      iconPosition="end"
      style={{ borderRadius: 5, backgroundColor: DefaultTheme.colors.grey4, justifyContent: 'space-between' }}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}

export default MoreButton;