import React, { useEffect, useState } from "react";
import { Spin, Modal, Button, Row, Col, notification, Upload, Form, Input, Switch, InputNumber } from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';
import FilesTable from "../../../components/filesTable";
import { FormConfig } from "../../../helpers/config";

const FormItem = Form.Item;
const { TextArea } = Input;

const BannersForm = props => {
  const [files, setFiles] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();
  const splash = Form.useWatch('splash', form);

  useEffect(() => {
    if (props.open === true) {
      setWorking(false);
      setFiles([]);
      form.resetFields();
    }
  }, [props.open]);

  const onRemove = id => {
    console.debug(id);
    setFiles(files.filter(f => f.file.uid !== id));
  }

  const beforeUpload = (file, fileList) => {
    setWorking(true);

    let promises = [];
    promises = fileList?.map(file => {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = e => resolve({
          id: file.uid,
          name: file.name,
          file: file,
          image: e.target.result
        });
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then(results => {
        setWorking(false);
        setFiles([...results]);
      })
      .catch(error => setWorking(false));

    return false;
  }

  const onFinish = async () => {
    setWorking(true);
    try {
      const values = await form.validateFields();

      let file = files[0];
      let data = null;
      if (!props.banner) {
        data = new FormData();
        data.append('titulo', values.titulo || '');
        data.append('extra_1', values.extra_1 || '');
        data.append('url', values.url || '');
        data.append('splash', values.splash || false);
        data.append('active_seconds', values.active_seconds || 0);
        data.append('orden', props.orden);
        data.append('publicada', false);
        data.append('banner_image', file.file);
      }
      else {
        data = {
          id: props.banner.id,
          titulo: values.titulo,
          extra_1: values.extra_1,
          url: values.url,
          splash: values.splash,
          active_seconds: values.active_seconds,
        }
      }

      try {
        await props.saveBanner(data);

        if (props.onUpload) {
          await props.onUpload();
        }
        notification.success({
          message: 'Exito',
          description: `Banner ${(props.banner ? 'actualizado' : 'subido')} correctamente`,
          placement: 'bottomRight'
        });
        props.onClose();
      }
      catch (error) {
        console.debug(error);
      }
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <Modal
      title={`${props.banner ? 'Editar' : 'Nuevo'} Banner`}
      width="60%"
      open={props.open}
      onOk={onFinish}
      onCancel={props.onClose}
      footer={[
        <Button key="cancel" onClick={props.onClose} disabled={working}>Cancelar</Button>,
        <Button key="accept" type="primary" onClick={onFinish} disabled={(!props.banner && files.length === 0) || working}>Aceptar</Button>,
      ]}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form
              {...FormConfig.DefaultLayout}
              form={form}
              name="formulario"
              //onFinish={onFinish}
              initialValues={{ ...props.banner }}
              scrollToFirstError
            >
              <FormItem name="titulo" label="Titulo">
                <Input maxLength={255} showCount />
              </FormItem>

              <FormItem name="extra_1" label="Descripción">
                <TextArea rows={6} maxLength={255} showCount />
              </FormItem>

              <FormItem name="url" label="Url">
                <Input maxLength={255} showCount />
              </FormItem>

              <FormItem name="splash" label="Splash" valuePropName="checked">
                <Switch />
              </FormItem>

              {splash &&
                <FormItem name="active_seconds" label="Segundos activo">
                  <InputNumber
                    max={100}
                    min={0}
                    defaultValue={10}
                    style={{ width: '100%' }}
                  />
                </FormItem>}
            </Form>
          </Col>
          {!props.banner &&
            <>
              <Col span={24}>
                <Upload.Dragger
                  name="files"
                  multiple={false}
                  onRemove={onRemove}
                  beforeUpload={beforeUpload}
                  fileList={files}
                  accept={props.filetypes}
                  showUploadList={false}
                >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p className="ant-upload-text">Haga click o arrastre un archivo para importar</p>
                  <p className="ant-upload-hint">Solo se aceptan archivos {props.filetypes}</p>
                </Upload.Dragger>
              </Col>
              {files.length > 0 &&
                <Col span={24}>
                  <FilesTable
                    files={files}
                    showActions={working === false}
                    onDelete={onRemove}
                    onFileNameChange={(id, name) => {
                      let filesAux = [...files];
                      let fileIdx = filesAux.findIndex(f => f.id === id);
                      if (fileIdx > -1) {
                        filesAux[fileIdx].name = name;
                      }
                      setFiles(filesAux);
                    }}
                  />
                </Col>
              }
            </>
          }
        </Row>
      </Spin>
    </Modal>
  );
}

export default BannersForm;