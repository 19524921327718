import React, { useState } from "react";
import { Space, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import parser from "../../../helpers/parser";
import ViewButton from "../../../components/buttons/viewButton";
import ImagePreview from "../../../components/ImagePreview";
import moment from "moment";

const DescuentosClubTable = props => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Imagen (500x400)', dataIndex: 'file', align: 'center',
      render: (text, record) => (
        record.assets.length > 0 ? <ImagePreview file={record.assets[0]?.file} /> : ''
      )
    },
    { title: 'Grupo/Sector', dataIndex: ['grupo', 'descripcion'], align: 'center' },
    { title: 'Rubro', dataIndex: ['rubro', 'descripcion'], align: 'center' },
    { title: 'SubRubro', dataIndex: ['sub_rubro', 'descripcion'], align: 'center' },
    {
      title: 'Descuento', dataIndex: 'descuento', align: 'center',
      render: text => <span>{text}%</span>
    },
    {
      title: 'Fecha desde', dataIndex: 'fecha_desde', align: 'center',
      render: text => parser.date(text)
    },
    {
      title: 'Fecha hasta', dataIndex: 'fecha_hasta', align: 'center',
      render: text => {
        let hoy = moment();
        return <Tag color={moment(text).diff(hoy) < 0 ? 'orange' : 'green'}>{parser.date(text)}</Tag>
      }
    },
    {
      title: 'Solo efectivo', dataIndex: 'solo_efectivo', align: 'center',
      render: text => <span>{text == true ? 'SI' : 'NO'}</span>
    },
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        <Space>
          <ViewButton onClick={() => navigate('./view/' + record.id)} />
        </Space>
      )
    },
  ];

  return (
    <Table
      size="small"
      loading={props.working}
      columns={columns}
      dataSource={props.descuentos}
      rowKey={record => record.id}
      pagination={{ hideOnSinglePage: true, pageSize: 10 }}
      scroll={{ x: '50%' }}
    />
  );
}

export default DescuentosClubTable;