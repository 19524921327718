import styled from "styled-components";
import { AppConfig } from "../../../helpers/config";
import pattern from '../assets/pattern_1.png';

const Container = styled('div')`
  position: relative;
  width: 100%;
`;

const StyledFooter = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  background-image: url(${pattern});
  opacity: ${props => props.mode === 'light' ? '0.1' : '0.2'};
`

const StyledTextContainer = styled('div')`
  position: relative;
  z-index: 1;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding: 10px;

  small {
    font-weight: bold;
  }
`;

const AppFooter = () => {
  return (
    <Container>
      {/*<StyledFooter />*/}
      <StyledTextContainer>
        <small>{AppConfig.footer}</small>
      </StyledTextContainer>
    </Container>
  );
}

export default AppFooter;