
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import ParametricasList from "./components/parametricasList";
import GruposPage from "./grupos/gruposPage";
import RubrosPage from "./rubros/rubrosPage";
import ParametrosPage from "./parametros/parametrosPage";
 
const ParametricasPage = () => {
  return (
    <Routes>
      <Route index element={<ParametricasList />} />
      <Route path="/grupos/*" element={<GruposPage />} />
      <Route path="/rubros/*" element={<RubrosPage />} />
      <Route path="/parametros/*" element={<ParametrosPage />} />
    </Routes>
  );
}

export default ParametricasPage;