import React, { useState } from "react";
import { Space, Table } from "antd";
import { useNavigate } from "react-router-dom";
import parser from "../../../helpers/parser";
import ViewButton from "../../../components/buttons/viewButton";
import NotifStatusTag from "./notifStatusTag";
import CancelButton from "../../../components/buttons/cancelButton";

const NotificacionesTable = props => {
  const navigate = useNavigate();
  const [working, setWorking] = useState(false);

  const columns = [
    { title: 'Titulo', dataIndex: 'title', align: 'center', },
    {
      title: 'Estado', dataIndex: 'status', align: 'center',
      render: text => <NotifStatusTag status={text} />
    },
    {
      title: 'Enviada el', dataIndex: 'created_at', align: 'center',
      render: text => parser.datetime(text)
    },
    { title: 'Creado por', dataIndex: ['user', 'email'], align: 'center', },
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        <Space>
          <ViewButton onClick={() => navigate('./view/' + record.id)} />
          {(record.status?.toUpperCase() == 'INGRESADA' || record.status?.toUpperCase() == 'ENVIANDO') &&
            <CancelButton onConfirm={() => props.cancelNotificacion(record.id)} />
          }
        </Space>
      )
    },
  ];

  return (
    <Table
      size="small"
      loading={working}
      columns={columns}
      dataSource={props.notificaciones}
      rowKey={record => record.id}
      pagination={{ hideOnSinglePage: true, pageSize: 10 }}
      scroll={{ x: '50%' }}
    />
  );
}

export default NotificacionesTable;