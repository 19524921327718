import moment from 'moment';
import packageJson from '../../package.json'

let year = moment().format('YYYY');
year = year === '2023' ? year : '2023 - ' + year

const AppConfig = {
  siteName: 'El Palacio de la Oportunidad',
  footer: `B12 Software - HyC - ${packageJson.version} - ${year}`,
  version: packageJson.version
};

const FormConfig = {
  DefaultRules: [{ required: true, message: 'Por favor ingrese un valor' }],
  DefaultLayout: {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  },
  DefaultSelectFilter: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  FullColLayout: {
    labelCol: { lg: 4, md: 8, sm: 8, xs: 8 },
    wrapperCol: { lg: 20, md: 16, sm: 16, xs: 16 }
  },
};

const MomentConfig = {
  tz: 'America/Argentina/Buenos_Aires',
};

export { AppConfig, FormConfig, MomentConfig }