import React, { useEffect, useState } from "react";
import DescuentosArticuloApi from "../api/descuentosArticuloApi";
import DescuentosTable from "./descuentosArticuloTable";
import PageContainer from "../../../components/pageContainer";
import TableSearch from "../../../components/tableSearch";
import { Button, Col, Row, Spin } from "antd";
import { CloudDownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import parser from "../../../helpers/parser";
import Restrict from "../../../components/restrict";
import { Consts } from "../../../helpers/consts";

const DescuentosArticuloList = props => {
  const [descuentos, setDescuentos] = useState([]);
  const [descuentosFiltradas, setDescuentosFiltradas] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadDescuentos();
  }, []);

  useEffect(() => {
    filterDescuentos();
  }, [searchText]);

  const loadDescuentos = async () => {
    setWorking(true);
    try {
      const response = await DescuentosArticuloApi.get();
      setDescuentos(response);
      setDescuentosFiltradas(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const filterDescuentos = () => {
    let descuentosAux = [...descuentos];
    if (searchText != '') {
      descuentosAux = descuentosAux.filter(x =>
        x.descuento.toLowerCase().indexOf(searchText) > -1 ||
        x.monto_desde.toLowerCase().indexOf(searchText) > -1
      )
    }
    setDescuentosFiltradas(descuentosAux);
  }

  return (
    <PageContainer
      title="Descuentos artículo"
      extra={
        <Restrict to={[Consts.ROLES.ADMIN]}>
          <CSVLink
            data={descuentosFiltradas.map(x => {
              return {
                id: x.id,
                titulo: x.titulo,
                descripcion: x.descripcion,
                extra: x.extra_1,
                descuento: x.descuento + '%',
                monto_desde: parser.currency(x.monto_desde),
                creado_el: parser.datetime(x.create_at),
                actualizado_el: parser.date(x.updated_at),
              }
            })}
            filename="descuentos"
          >
            <Button type="primary" icon={<CloudDownloadOutlined />}>Descargar Excel</Button>
          </CSVLink>
        </Restrict>
      }
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <TableSearch
              style={{ width: '100%' }}
              placeholder="Buscar por descuento, monto"
              value={searchText}
              onChange={e => setSearchText(e.target.value.toLowerCase())}
            />
          </Col>
          <Col span={24}>
            <DescuentosTable
              descuentos={descuentosFiltradas}
            />
          </Col>
        </Row>
      </Spin>
    </PageContainer>
  );
}

export default DescuentosArticuloList;