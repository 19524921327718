import Fetcher from "../../../../helpers/fetcher";

const GruposApi = {
  get: id => {
    let url = '/grupos/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
}

export default GruposApi;