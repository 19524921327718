import React, { useEffect, useState } from "react";
import { Descriptions, Spin, Row, Col, Tabs } from "antd";
import parser from "../../../helpers/parser";
import PageContainer from "../../../components/pageContainer";
import { useNavigate, useParams } from "react-router-dom";
import ClientesApi from "../api/clientesApi";
import CuponesTable from './cuponesTable';
import CuponesUsadosTable from "./cuponesUsadosTable";
import moment from "moment";

const DescItem = Descriptions.Item;
const TabPane = Tabs.TabPane;

const ClientesView = props => {
  const navigate = useNavigate();
  const params = useParams();
  const [cliente, setCliente] = useState(undefined);
  const [cupones, setCupones] = useState([]);
  const [cuponesUsados, setCuponesUsados] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadCliente();
  }, []);

  const loadCliente = async () => {
    setWorking(true);
    try {
      const response = await ClientesApi.get(params.id);
      setCliente(response);

      const respCupones = await ClientesApi.getCupones(response.dni);
      setCupones(respCupones);
      let cuponesUsadosAux = respCupones.reduce((acc, current) => acc.concat(current.usados), []);
      setCuponesUsados(cuponesUsadosAux.sort((a, b) => moment(b.usado_at) - moment(a.usado_at)));
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title={`Cliente: ${cliente?.nombre_completo || ''}`}
      onBack={() => navigate(-1)}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Descriptions size="small" column={1} bordered>
              <DescItem label="Email"><b>{cliente?.email}</b></DescItem>
              <DescItem label="Nombre"><b>{cliente?.name}</b></DescItem>
              <DescItem label="DNI"><b>{cliente?.dni}</b></DescItem>
              <DescItem label="Nombre completo"><b>{cliente?.nombre_completo}</b></DescItem>
              <DescItem label="Localidad"><b>{cliente?.localidad}</b></DescItem>
              <DescItem label="Código postal"><b>{cliente?.codigo_postal}</b></DescItem>
              <DescItem label="Teléfono"><b>{cliente?.telefono}</b></DescItem>
              <DescItem label="Fecha nacimiento"><b>{parser.date(cliente?.fecha_nacimiento)}</b></DescItem>
              <DescItem label="Fecha alta"><b>{parser.datetime(cliente?.fecha_alta)}</b></DescItem>
            </Descriptions>
          </Col>
          <Col span={24}>
            <h2>Cupones</h2>

            <Tabs type="card" hideAdd>
              <TabPane key={'vigentes'} tab={'Vigentes'}>
                <CuponesTable
                  cupones={cupones.filter(x => x.vigente == true)}
                />
              </TabPane>
              <TabPane key={'usados'} tab={'Usados'}>
                <CuponesUsadosTable
                  cupones={cuponesUsados}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Spin>
    </PageContainer>
  );
}

export default ClientesView;

