import React, { useEffect, useState } from "react";
import { Spin, Row, Col, Tabs, Descriptions } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import DescuentosArticuloApi from "../api/descuentosArticuloApi";
import MediaTable from "../../../components/media/mediaTable";
import PageContainer from "../../../components/pageContainer";
import UploadButton from "../../../components/buttons/uploadButton";
import parser from "../../../helpers/parser";
import DescuentosArticuloForm from "./descuentosArticuloForm";
import EditButton from "../../../components/buttons/editButton";

const { TabPane } = Tabs;
const DescItem = Descriptions.Item;

const DescuentosArticuloView = props => {
  const navigate = useNavigate();
  const params = useParams();
  const [descuento, setDescuento] = useState(undefined);
  const [showDescuentoForm, setShowDescuentoForm] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadDescuento();
  }, []);

  const loadDescuento = async () => {
    setWorking(true);
    try {
      const response = await DescuentosArticuloApi.get(params.id);
      setDescuento(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title={`Descuento artículo: ${descuento?.articulo
        ? `[${descuento?.nroart}] ${descuento?.articulo?.descrabrev}`
        : ''
        }`
      }
      onBack={() => navigate(-1)}
      extra={<EditButton onClick={() => setShowDescuentoForm(true)} />}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Descriptions size="small" column={1} bordered>
              <DescItem label="Nro"><b>{descuento?.nroart}</b></DescItem>
              <DescItem label="Articulo"><b>{descuento?.articulo?.descrabrev}</b></DescItem>
              <DescItem label="Observaciones"><b>{descuento?.leyenda}</b></DescItem>
              <DescItem label="Url"><b>{descuento?.url}</b></DescItem>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Descriptions size="small" column={1} bordered>
              <DescItem label="Precio base"><b>{parser.currency(descuento?.articulo?.precio1f)}</b></DescItem>
              <DescItem label="Precio oferta"><b>{parser.currency(descuento?.preciooferta)}</b></DescItem>
              <DescItem label="Margen"><b>{parser.currency(descuento?.margen)}</b></DescItem>
              <DescItem label="Stock disponible"><b>{descuento?.stockdisponible}</b></DescItem>
              <DescItem label="Fecha desde"><b>{parser.date(descuento?.fechadesde)}</b></DescItem>
              <DescItem label="Fecha vencimiento"><b>{parser.date(descuento?.fechavenc)}</b></DescItem>
              <DescItem label="Incluye IVA"><b>{descuento?.incluyeiva == true ? 'SI' : 'NO'}</b></DescItem>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Tabs
              defaultActiveKey={['Archivos']}
              type="editable-card"
              hideAdd
            >
              <TabPane
                key="Archivos"
                tab={<>Archivos <small>({descuento?.assets?.length})</small></>}
                closeIcon={
                  <UploadButton
                    btnType="link"
                    uploadFile={(data) => DescuentosArticuloApi.uploadFile(data)}
                    onUpload={() => loadDescuento()}
                    entity_id={descuento?.nrooferta}
                    filetypes={'image/jpeg, image/jpg, image/png, image/gif'}
                    disabled={descuento?.assets?.length > 0}
                  />
                }
              >
                <MediaTable
                  files={descuento?.assets}
                  onDelete={async media => {
                    await DescuentosArticuloApi.deleteFile(media.id);
                    loadDescuento();
                  }}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Spin>

      <DescuentosArticuloForm
        open={showDescuentoForm}
        onClose={() => setShowDescuentoForm(false)}
        descuento={descuento}
        loadDescuento={loadDescuento}
      />
    </PageContainer>
  );
}

export default DescuentosArticuloView;

