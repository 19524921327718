import Fetcher from "../../../helpers/fetcher";

const ClientesApi = {
  get: (id, perPage, page, search, fecha_alta_desde, fecha_alta_hasta) => {
    let url = '/usuarios/single';
    if (id) { url += '/' + id; }
    else {
      url += `?per_page=${perPage}&page=${page}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (fecha_alta_desde) {
        url += `&fecha_alta_desde=${fecha_alta_desde}`;
      }
      if (fecha_alta_hasta) {
        url += `&fecha_alta_hasta=${fecha_alta_hasta}`;
      }
    }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  getCupones: dni => {
    let url = '/tipos-de-cupones/find-by-dni/' + dni;
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  getClientesPorRangoEdad: () => {
    return Fetcher
      .get('/usuarios/rango-edad')
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  getClientesPorProvincia: () => {
    return Fetcher
      .get('/usuarios/provincia')
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  getClientesPorLocalidad: () => {
    return Fetcher
      .get('/usuarios/localidad')
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  getClientesActivos: () => {
    return Fetcher
      .get('/usuarios/activo-12meses')
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
}

export default ClientesApi;