import { Space, Table } from "antd";
import React from "react";
import parser from "../../helpers/parser";
import { FaFilePdf, FaFileImage } from 'react-icons/fa';
import DeleteButton from "../buttons/deleteButton";
import ImagePreview from "../ImagePreview";
import Utils from "../../helpers/utils";

const MediaTable = props => {
  const columns = [
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        record.file_type?.indexOf('image') > -1
          ? <FaFileImage style={{ color: 'orange' }} />
          : <FaFilePdf style={{ color: 'red' }} />
      )
    },
    {
      title: 'Nombre', dataIndex: 'file_name',
      render: (text, record) => <a href={Utils.getBaseUrl() + record.file} target="_blank" alt={text} rel="noreferrer">{text}</a>
    },
    {
      title: 'Fecha alta', dataIndex: 'created_at', width: '10%', align: 'center',
      render: text => parser.date(text)
    },
    {
      title: '', dataIndex: 'file_extension', width: '20%', align: 'center',
      render: (text, record) => <ImagePreview file={record.file} />
    },
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        <Space>
          <DeleteButton onConfirm={() => props.onDelete(record)} />
        </Space>
      )
    },
  ];

  return (
    <Table
      rowKey={record => record.id}
      columns={columns}
      dataSource={props.files}
      pagination={false}
      size="small"
    />
  );
}

export default MediaTable;