import React, { useState } from "react";
import { Space, Table } from "antd";
import RubrosDetail from "./rubrosDetail";
import ViewButton from "../../../../components/buttons/viewButton";

const RubrosTable = props => {
  const [selectedRubro, setSelectedRubro] = useState(undefined);
  const [showRubroDetail, setShowRubroDetail] = useState(false);
  const [working, setWorking] = useState(false);

  const columns = [
    { title: 'Rubro', dataIndex: 'codigo_rubro', align: 'center' },
    { title: 'Subrubro', dataIndex: 'codigo_subrubro', align: 'center' },
    { title: 'Descripción', dataIndex: 'descripcion', },
    {
      title: '', dataIndex: 'acciones', width: '10%', align: 'center',
      render: (text, record) => <Space>
        <ViewButton onClick={() => { setSelectedRubro(record); setShowRubroDetail(true) }} />
      </Space>
    },
  ];

  return (
    <>
      <Table
        size="small"
        loading={working}
        columns={columns}
        dataSource={props.rubros}
        rowKey={record => record.id}
        pagination={{ hideOnSinglePage: true, pageSize: 10, showSizeChanger: false }}
        scroll={{ x: '50%' }}
      />
      <RubrosDetail
        open={showRubroDetail}
        onClose={() => setShowRubroDetail(false)}
        rubro={selectedRubro}
      />
    </>
  );
}

export default RubrosTable;