import { Consts } from "./consts";

export default class Utils {

  static generateHash = length => {
    length = length ? length : 10;
    let charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".match(/./g);
    let text = "";
    for (let i = 0; i < length; i++) {
      text += charset[Math.floor(Math.random() * charset.length)];
    }
    return text;
  }

  static generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  static isTestEnv = () => {
    return window.location?.host?.indexOf(':88') > -1 ||
      window.location?.hostname?.indexOf('localhost') > -1
  }

  static getBaseUrl = () => {
    return `${window.location.protocol}${(window.location.host.indexOf('localhost') > -1
      ? `//${Consts.APP.SERVER_IP}/`
      : `//${window.location.host}/`)}`
  }

  static colorLuminance = (hex, lum) => {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }
}