import React, { FC, useEffect, useState } from "react";
import { Image, Space, Switch, Table } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PlantasApi from "../api/plantasApi";
import DeleteButton from "../../../components/buttons/deleteButton";
import ImagePreview from "../../../components/ImagePreview";
import ViewButton from "../../../components/buttons/viewButton";
import { useLocation, useNavigate } from "react-router-dom";
import EditButton from "../../../components/buttons/editButton";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const PlantasTable = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const [plantas, setPlantas] = useState([]);

  useEffect(() => {
    if (props.plantas) {
      setPlantas(props.plantas.sort((a, b) => a.orden - b.orden));
    }
  }, [props.plantas]);

  const columns = [
    { key: 'sort', width: 50, align: 'center' },
    { title: 'Iniciales', dataIndex: 'iniciales', align: 'center' },
    {
      title: 'Imagen (1200x500)', dataIndex: 'file', align: 'center',
      render: (text, record) => record.assets.length > 0
        ? <ImagePreview file={record.assets[0]?.file} />
        : ''
    },
    { title: 'Titulo', dataIndex: 'titulo', align: 'center' },
    { title: 'Subtitulo', dataIndex: 'subtitulo', align: 'center' },
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        <Space>
          <ViewButton onClick={() => navigate(location.pathname + '/view/' + record.id)} />
          <EditButton onClick={() => navigate(location.pathname + '/edit/' + record?.id)} />
          <DeleteButton onConfirm={() => deletePlanta(record.id)} />
        </Space>
      )
    },
  ]

  const deletePlanta = async id => {
    props.setWorking(true);
    try {
      await PlantasApi.delete(id);
      props.loadPlantas();
    }
    catch (error) {
      console.debug(error);
    }
    props.setWorking(false);
  }

  const onDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id) {
      /*let plantasAux = [...plantas];

      plantas.map((banner, idx) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });*/

      props.setWorking(true);
      try {
        let plantasAux = [...plantas];
        const activeIndex = plantasAux.findIndex(i => i.id === active.id);
        const overIndex = plantasAux.findIndex(i => i.id === over?.id);
        plantasAux = arrayMove(plantasAux, activeIndex, overIndex);
        for (let i = 0; i < plantasAux.length; i++) {
          if (plantasAux[i].orden != i) {
            await PlantasApi.cambiarOrden(plantasAux[i].id, i);
          }
        }
        await props.loadPlantas();
      }
      catch (error) {
        console.debug(error);
      }
      props.setWorking(false);
    }
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={plantas.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          rowKey="id"
          size="small"
          columns={columns}
          dataSource={plantas}
          pagination={{ pageSize: 10, showSizeChanger: false, hideOnSinglePage: true }}
          components={{
            body: {
              row: Row,
            },
          }}
          scroll={{ x: '50%' }}
        />

      </SortableContext>
    </DndContext>
  )
}

export default PlantasTable;
