import React, { useEffect, useState } from "react";
import PlantasApi from "../api/plantasApi";
import PageContainer from "../../../components/pageContainer";
import { Spin } from "antd";
import PlantasTable from "./plantasTable";

const PlantasList = props => {
  const [plantas, setPlantas] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadPlantas();
  }, []);

  const loadPlantas = async () => {
    setWorking(true);
    try {
      const response = await PlantasApi.get();
      setPlantas(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title="Plantas"
      defaultExtra
    >
      <Spin spinning={working}>
        <PlantasTable
          plantas={plantas.sort((a, b) => a.orden - b.orden)}
          loadPlantas={loadPlantas}
          setWorking={setWorking}
        />
      </Spin>
    </PageContainer>
  );
}

export default PlantasList;