
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import CuponesList from "./components/cuponesList";
import CuponesView from "./components/cuponesView";
 
const CuponesPage = () => {
  return (
    <Routes>
      <Route index element={<CuponesList />} />
      <Route path="/view/:id" element={<CuponesView />} />
    </Routes>
  );
}

export default CuponesPage;