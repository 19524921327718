import React, { useEffect, useState } from "react";
import NotificacionesApi from "../api/notificacionesApi";
import NotificacionesTable from "./notificacionesTable";
import PageContainer from "../../../components/pageContainer";
import TableSearch from "../../../components/tableSearch";
import { Button, Col, Row, Spin } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import ReloadButton from "../../../components/buttons/reloadButton";
import moment from "moment";

const NotificacionesList = props => {
  const navigate = useNavigate();
  const [notificaciones, setNotificaciones] = useState([]);
  const [notificacionesFiltradas, setNotificacionesFiltradas] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadNotificaciones();
    /*const timer = setInterval(async () => {
      await loadNotificaciones();
    }, 10000); // 10 segs

    return () => clearInterval(timer);*/
  }, []);

  useEffect(() => {
    filterNotificaciones(notificaciones);
  }, [searchText]);

  const loadNotificaciones = async () => {
    setWorking(true);
    try {
      const response = await NotificacionesApi.get();
      setNotificaciones(response);
      filterNotificaciones(response);
      //setNotificacionesFiltradas(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const filterNotificaciones = (notifs) => {
    let notificacionesAux = [...notifs];
    if (searchText != '') {
      notificacionesAux = notificacionesAux.filter(x =>
        x.title.toLowerCase().indexOf(searchText) > -1 ||
        x.body.toLowerCase().indexOf(searchText) > -1
      )
    }
    setNotificacionesFiltradas(notificacionesAux);
  }

  const cancelNotificacion = async (id) => {
    setWorking(true);
    try {
      await NotificacionesApi.cancel(id);
      await loadNotificaciones();
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title="Notificaciones"
      extra={[
        <Button key="1" type="primary" icon={<PlusOutlined />}
          onClick={() => navigate('./new')}>
          Nuevo
        </Button>
      ]}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <TableSearch
              style={{ width: '100%' }}
              placeholder="Buscar por titulo, mensaje"
              value={searchText}
              onChange={e => setSearchText(e.target.value.toLowerCase())}
            />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
          >
            <ReloadButton onClick={loadNotificaciones} />
          </Col>
          <Col span={24}>
            <NotificacionesTable
              notificaciones={notificacionesFiltradas.sort((a,b) => moment(b.created_at) - moment(a.created_at))}
              cancelNotificacion={cancelNotificacion}
            />
          </Col>
        </Row>
      </Spin>
    </PageContainer>
  );
}

export default NotificacionesList;