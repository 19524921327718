import React, { useEffect, useState } from "react";
import { Spin, Modal, Button, Row, Col, notification, Upload, Card, Input, Form, Select, Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { SaveOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormConfig } from "../../../helpers/config";
import PlantasApi from "../api/plantasApi";

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

const PlantasForm = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [planta, setPlanta] = useState(undefined);
  const [grupos, setGrupos] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    loadGrupos();
    if (location.pathname.includes('edit')) {
      loadPlanta(params.id);
    }
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [planta]);

  const loadGrupos = async () => {
    try {
      const response = await PlantasApi.getGrupos();
      setGrupos(response);
    }
    catch (error) {
      console.debug(error);
    }
  }

  const loadPlanta = async id => {
    setWorking(true);
    try {
      const response = await PlantasApi.get(id);
      setPlanta(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const onFinish = async values => {
    if (values.password !== values.newpassword) {
      notification.error({ message: 'Error', description: 'Los password deben ser iguales', placement: 'bottomRight' });
      return;
    }

    setWorking(true);
    try {
      let data = {
        id: planta?.id,
        name: values.nombre_completo,
        orden: planta?.orden,
        ...values,
      }

      await PlantasApi.save(data);
      notification.success({ message: 'Exito', description: 'Planta guardada correctamente', placement: 'bottomRight' });
      navigate(-1);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  };

  return (
    <Card>
      <PageHeader
        title={`${planta && planta.id ? 'Editar planta' : 'Nueva planta'}`}
        /*subTitle="Formulario"*/
        onBack={() => navigate(-1)}
      />
      <Spin spinning={working}>
        <Card>
          <Form
            {...FormConfig.DefaultLayout}
            form={form}
            name="formulario"
            onFinish={onFinish}
            initialValues={{
              ...planta,
              grupos: planta?.grupos.map(x => x.id)
              //fecha_nacimiento: planta?.fecha_nacimiento ? dayjs(planta.fecha_nacimiento) : null,
              //fecha_alta: planta?.fecha_alta ? dayjs(planta.fecha_alta) : dayjs(),
            }}
            scrollToFirstError
            autoComplete="off"
          >
            <FormItem name="iniciales" label="Iniciales" rules={[...FormConfig.DefaultRules]}>
              <Input maxLength={2} />
            </FormItem>

            <FormItem name="titulo" label="Título" rules={[...FormConfig.DefaultRules]}>
              <Input />
            </FormItem>

            <FormItem name="subtitulo" label="Subtítulo" rules={[...FormConfig.DefaultRules]}>
              <Input />
            </FormItem>

            <FormItem name="descripcion" label="Descripción" rules={[...FormConfig.DefaultRules]}>
              <TextArea rows={4} maxLength={255} showCount />
            </FormItem>

            <FormItem name="grupos" label="Grupos/Sectores" rules={FormConfig.DefaultRules}>
              <Select showSearch filterOption={FormConfig.DefaultSelectFilter} autoComplete="none"
                mode="multiple"
              >
                {grupos.map(grupo =>
                  <Option key={grupo.id} value={grupo.id}>{grupo.descripcion}</Option>
                )}
              </Select>
            </FormItem>

            <FormItem wrapperCol={{ span: 16, offset: 8 }}>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Guardar</Button>
            </FormItem>
          </Form>
        </Card>
      </Spin>
    </Card>
  );
}

export default PlantasForm;