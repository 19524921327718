import { Card, Col, Row, Spin } from "antd";
import PageContainer from "../../components/pageContainer";
import Phone from "../../components/phone/phone";
import ClientesChart from "./components/clientesChart";
import Widget from "./components/widget";
import { useEffect, useState } from "react";
import ClientesApi from "../clientes/api/clientesApi";
import { TbBox, TbCurrencyDollar, TbPhoto, TbTags, TbUsers } from "react-icons/tb";
import ClientesEdadChart from "./components/clientesEdadChart";
import BannersApi from "../banners/api/bannersApi";
import DescuentosClubApi from "../descuentos_club/api/descuentosClubApi";
import DescuentosMontoApi from "../descuentos_monto/api/descuentosMontoApi";
import ClientesLocalidadChart from "./components/clientesLocalidadChart";
import Restrict from "../../components/restrict";
import { Consts } from "../../helpers/consts";
import ClientesProvinciaChart from "./components/clientesProvinciaChart";
import DescuentosArticuloApi from "../descuentos_articulo/api/descuentosArticuloApi";
import { useNavigate } from "react-router-dom";

const DashboardPage = props => {
  const navigate = useNavigate();
  const [totalClientes, setTotalClientes] = useState(0);
  const [banners, setBanners] = useState([]);
  const [descuentosCategoria, setDescuentosCategoria] = useState([]);
  const [descuentosMonto, setDescuentosMonto] = useState([]);
  const [descuentosArticulo, setDescuentosArticulo] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadInfo();
  }, []);

  const loadInfo = async () => {
    setWorking(true);

    try {
      const respClientes = await ClientesApi.get(undefined, 1, 1);
      const respDescuentosCategoria = await DescuentosClubApi.getVigentes();
      const respDescuentosMonto = await DescuentosMontoApi.get();
      const respDescuentosArticulo = await DescuentosArticuloApi.get();
      const respBanners = await BannersApi.get();

      setTotalClientes(respClientes.total);
      setDescuentosCategoria(respDescuentosCategoria);
      setDescuentosMonto(respDescuentosMonto);
      setDescuentosArticulo(respDescuentosArticulo);
      setBanners(respBanners);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer>
      <Row gutter={[16, 16]}>
        <Restrict to={[Consts.ROLES.ADMIN, Consts.ROLES.GERENTE]}>
          <Col span={24}>
            <Spin spinning={working}>
              <Row gutter={[16, 16]}>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Widget
                    title={totalClientes}
                    message="Clientes registrados"
                    icon={<TbUsers />}
                    bgColor={'#0d6efd'}
                    onClick={() => navigate('/clientes')}
                  />
                </Col>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Widget
                    title={banners.length}
                    message="Banners activos"
                    icon={<TbPhoto />}
                    bgColor={'#dc3545'}
                    onClick={() => navigate('/banners')}
                  />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Widget
                    title={descuentosArticulo.length}
                    message="Descuentos por artículo"
                    icon={<TbBox />}
                    bgColor={'#76448A'}
                    onClick={() => navigate('/descuentos-articulo')}
                  />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Widget
                    title={descuentosCategoria.length}
                    message="Descuentos por categoria"
                    icon={<TbTags />}
                    bgColor={'#1F618D'}
                    onClick={() => navigate('/descuentos-club')}
                  />
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Widget
                    title={descuentosMonto.length}
                    message="Descuentos por monto"
                    icon={<TbCurrencyDollar />}
                    bgColor={'#198754'}
                    onClick={() => navigate('/descuentos-monto')}
                  />
                </Col>
              </Row>
            </Spin>
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <Card title="Clientes por edad">
              <ClientesEdadChart />
            </Card>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Card title="Clientes por provincia">
              <ClientesProvinciaChart />
            </Card>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Card title="Clientes por localidad">
              <ClientesLocalidadChart />
            </Card>
          </Col>

          <Col span={24}>
            <Card title="Clientes activos">
              <ClientesChart />
            </Card>
          </Col>
        </Restrict>

        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Phone />
        </Col>
      </Row>
    </PageContainer>
  )
}

export default DashboardPage;