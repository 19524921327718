import { Descriptions, Drawer } from "antd"

const DescItem = Descriptions.Item;

const GruposDetail = props => {
  return (
    <Drawer
      title="Grupo"
      placement="right"
      onClose={props.onClose}
      open={props.open}
    >
      <Descriptions size="small" column={1} bordered>
      <DescItem label="Número"><b>{ props.grupo?.numero_grupo }</b></DescItem>
      <DescItem label="Descripción"><b>{ props.grupo?.descripcion }</b></DescItem>
    </Descriptions>
    </Drawer>
  )
}

export default GruposDetail;