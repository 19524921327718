import { Avatar, Button, Divider, Popover, Space, Tooltip } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthHelper from "../../helpers/authHelper";
import PerfilView from "../perfil/perfilView";

const StyledContainer = styled('div')`
  min-width: 200px;
  text-align: center;
`

const StyledDivider = styled(Divider)`
  margin: 0px;
`

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`

const UserButton = props => {
  const navigate = useNavigate();
  const [user] = useState(AuthHelper.getUser());
  const [popOpen, setPopOpen] = useState(false);
  const [showPerfil, setShowPerfil] = useState(false);

  const logout = () => {
    AuthHelper.logout(() => navigate("/login"));
  }

  const openPerfil = () => {
    setPopOpen(false);
    setShowPerfil(true)
  }

  return (
    <>
      <Popover
        open={popOpen}
        onOpenChange={visible => setPopOpen(visible)}
        placement="bottomRight"
        content={
          <StyledContainer>
            <Space direction='vertical' style={{ width: '100%' }}>
              <Button block type="text" onClick={openPerfil}>Mi Perfil</Button>
              <StyledDivider />
              <Button block type="text" onClick={logout}>Salir</Button>
            </Space>
          </StyledContainer>
        }
        trigger="click"
      >
        <Tooltip title="Usuario" placement="left">
          <StyledAvatar size="large" icon={<UserOutlined />} />
        </Tooltip>
      </Popover>

      <PerfilView
        open={showPerfil}
        onClose={() => setShowPerfil(false)}
      />
    </>
  )
}

export default UserButton;