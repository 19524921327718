import Fetcher from "../../../helpers/fetcher";

const DescuentosArticuloApi = {
  get: nrooferta => {
    let url = 'descuentos-articulo/';
    if(nrooferta){ url += nrooferta; }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  save: data => {
    if(!data.nrooferta){
      return Fetcher
        .post('descuentos-articulo', data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
    else{
      return Fetcher
        .put('descuentos-articulo/' + data.nrooferta, data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
  },
  /*delete: id => {
    return Fetcher
      .delete('descuentos-articulo/' + id, {})
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },*/
  uploadFile: (formdata) => {
    formdata.append('entity', 'DESCUENTO_ARTICULO');

    return Fetcher
      .post('/assets', formdata)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  deleteFile: (nrooferta) => {
    return Fetcher
      .delete('/assets/' + nrooferta)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
}

export default DescuentosArticuloApi;