import axios from 'axios';

export default class Fetcher {
  static get(path, callback) {
    return axios.get(path, { callback });
  }

  static post(path, data = {}, callback) {
    return axios.post(path, data, { callback });
  }

  static postMultipart(path, data = {}, callback) {
    return axios.post(path, data, { callback, headers: { 'Content-Type': `multipart/form-data; boundary=${data._boundary}` } });
  }

  static put(path, data = {}, callback) {
    return axios.put(path, data, { callback });
  }

  static delete(path, callback) {
    return axios.delete(path, { callback });
  }

  static patch(path, data = {}, callback) {
    return axios.patch(path, data, { callback });
  }

  static download(path) {
    return axios({ url: path, method: 'GET', responseType: 'blob' });
  }
}
