import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import { Table, Tag, Space, notification, Col, Row, Button } from "antd";
import { CloudDownloadOutlined } from '@ant-design/icons';
import UsuariosView from './usuariosView';
import UsuariosApi from "../api/usuariosApi";
import AuthHelper from "../../../helpers/authHelper";
import TableSearch from "../../../components/tableSearch";
import PageContainer from "../../../components/pageContainer";
import ViewButton from "../../../components/buttons/viewButton";
import EditButton from "../../../components/buttons/editButton";
import ResetPassButton from "../../../components/buttons/resetPassButton";
import DeleteButton from "../../../components/buttons/deleteButton";
import { CSVLink } from "react-csv";
import parser from "../../../helpers/parser";

const UsuariosList = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useState(AuthHelper.getUser());
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [working, setWorking] = useState(false);
  const [currentItem, setCurrentItem] = useState(undefined);
  const [showItem, setShowItem] = useState(false);
  //const [showAddSolucionModal, setShowAddSolucionModal] = useState(false);

  useEffect(() => {
    loadUsuarios();
  }, []);

  useEffect(() => {
    filterUsuarios();
  }, [searchText]);

  const columns = [
    { title: 'Dni', dataIndex: 'dni', key: 'dni' },
    { title: 'Nombre', dataIndex: 'name', key: 'name' },
    { title: 'Rol', dataIndex: 'role', key: 'role' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      key: 'actions', align: 'right',
      render: (text, record) => {
        return <Space size="small">
          <ViewButton onClick={() => { setShowItem(true); setCurrentItem(record); }} />
          <EditButton onClick={() => navigate(location.pathname + '/edit/' + record.id)} />
          {user.id !== record.id && <ResetPassButton title="¿Cambiar clave a '1234'?" onConfirm={() => resetPassword(record)} />}
          <DeleteButton title="¿Está seguro de querer eliminar el usuario?" onConfirm={() => deleteUsuario(record)} />
        </Space>
      }
    }
  ];

  const loadUsuarios = async () => {
    setWorking(true);
    try {
      const response = await UsuariosApi.get();
      setUsuarios(response);
      setUsuariosFiltrados(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const filterUsuarios = () => {
    let usuariosAux = [...usuarios];
    if (searchText != '') {
      usuariosAux = usuariosAux.filter(x =>
        x.dni.toLowerCase().indexOf(searchText) > -1 ||
        x.name.toLowerCase().indexOf(searchText) > -1 ||
        x.nombre_completo.toLowerCase().indexOf(searchText) > -1 ||
        x.email.toLowerCase().indexOf(searchText) > -1
      )
    }
    setUsuariosFiltrados(usuariosAux);
  }

  const deleteUsuario = async record => {
    setWorking(true);
    try {
      await UsuariosApi.delete(record.id)
      notification.success({ message: 'Exito', description: 'Usuario eliminado correctamente', placement: 'bottomRight' });
      await loadUsuarios();
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const resetPassword = async record => {
    setWorking(true);
    try {
      await UsuariosApi.resetPassword(record.id, '123');
      notification.success({ message: 'Exito', description: 'Contraseña modificada correctamente', placement: 'bottomRight' });
      await loadUsuarios();
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title="Usuarios"
      defaultExtra
    >
      <Row gutter={[16, 16]}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <TableSearch
            style={{ width: '100%' }}
            placeholder="Buscar por dni, nombre, email"
            value={searchText}
            onChange={e => setSearchText(e.target.value.toLowerCase())}
          />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}
          style={{ textAlign: 'right' }}
        >
          <CSVLink
            data={usuariosFiltrados.map(x => {
              return {
                id: x.id,
                dni: x.dni,
                nombre: x.name,
                nombre_completo: x.nombre_completo,
                email: x.email,
                fecha_nacimiento: parser.date(x.fecha_nacimiento),
                telefono: x.telefono,
                localidad: x.localidad,
                codigo_postal: x.codigo_postal,
                fecha_alta: parser.date(x.fecha_alta),
              }
            })}
            filename="clientes"
          >
            <Button type="primary" icon={<CloudDownloadOutlined />}>Descargar Excel</Button>
          </CSVLink>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            loading={working}
            columns={columns}
            dataSource={usuariosFiltrados?.sort((a, b) => a.email.localeCompare(b.email))}
            rowKey={record => record.id}
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </Col>
      </Row>

      <UsuariosView
        open={showItem}
        onClose={() => {
          setShowItem(false);
          setCurrentItem(undefined);
        }}
        usuario={currentItem}
      />
    </PageContainer>
  );
}

export default UsuariosList;