import { useState } from "react";
import { useNavigate } from "react-router";
import { Form, Input, Button } from "antd";
import LoginApi from '../../api/LoginApi';
import { FormConfig } from "../../../../helpers/config";

const FormItem = Form.Item;
const LoginForm = () => {
  const navigate = useNavigate();
  const [working, setWorking] = useState(false);

  const onFinish = async values => {
    setWorking(true);
    try {
      await LoginApi.login(values.email, values.password);
      navigate("/", { replace: true });
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  };

  return (
    <Form
      {...FormConfig.DefaultLayout}
      name="basic"
      initialValues={{ remember: true }}
      labelAlign="left"
      onFinish={onFinish}
    >
      <FormItem label="Email" name="email"
        rules={[
          ...FormConfig.DefaultRules,
          { type: 'email', message: 'Por favor ingrese un email válido' }
        ]}
      >
        <Input />
      </FormItem>

      <FormItem label="Password" name="password" rules={FormConfig.DefaultRules}>
        <Input.Password />
      </FormItem>

      <FormItem {...{ wrapperCol: { span: 24 } }}>
        <Button block type="primary" htmlType="submit" loading={working}>Ingresar</Button>
      </FormItem>
    </Form>
  );
};

export default LoginForm;
