import { ConfigProvider, theme as AntTheme } from 'antd';
import { useRecoilState } from 'recoil';
import { ThemeProvider } from 'styled-components';
import AppLayout from './pages/layout/AppLayout';
import themeState from './atoms/themeState';
import GlobalStyle from './styles/GlobalStyle';

const { useToken } = AntTheme;

const App = () => {
  const { token } = useToken();
  const [appTheme, setAppTheme] = useRecoilState(themeState);

  return (
    appTheme &&
    <ConfigProvider
      theme={appTheme}
    >
      <ThemeProvider
        theme={{
          token: { ...token, ...appTheme.token },
          components: { ...appTheme.components },
        }}
      >
        <GlobalStyle />
        <AppLayout />
      </ThemeProvider>
    </ConfigProvider>
  );
}

export default App;
