import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;*/
    font-family: 'Nunito';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .ant-layout .ant-layout-sider-children {
    background-color: #0a6bf5;
  }

  .ant-menu-light.ant-menu-submenu-popup>.ant-menu, .ant-menu-light>.ant-menu.ant-menu-submenu-popup>.ant-menu {
    background-color: #0a6bf5;
  }

  .ant-menu-light.ant-menu-submenu>.ant-menu, .ant-menu-light>.ant-menu.ant-menu-submenu>.ant-menu {
    background-color: #0a6bf5;
  }
`;

export default GlobalStyle;