import React, { useEffect } from "react";
import { Tag, Descriptions, Drawer } from "antd";
import parser from "../../../helpers/parser";

const DescItem = Descriptions.Item;

const UsuariosView = props => {
  useEffect(() => {
    if (props.visible !== true) { return; }
  }, [props.visible]);

  return (
    <Drawer
      title={`Usuario: ${props.usuario?.email}`}
      placement="right"
      closable={false}
      onClose={props.onClose}
      open={props.open}
      width={'50%'}
    >
      <Descriptions size="small" column={1} bordered>
        <DescItem label="DNI"><b>{props.usuario?.dni}</b></DescItem>
        <DescItem label="Nombre completo"><b>{props.usuario?.nombre_completo}</b></DescItem>
        <DescItem label="Localidad"><b>{props.usuario?.localidad}</b></DescItem>
        <DescItem label="Código postal"><b>{props.usuario?.codigo_postal}</b></DescItem>
        <DescItem label="Teléfono"><b>{props.usuario?.telefono}</b></DescItem>
        <DescItem label="Fecha nacimiento"><b>{parser.date(props.usuario?.fecha_nacimiento)}</b></DescItem>
        <DescItem label="Fecha alta"><b>{parser.date(props.usuario?.fecha_alta)}</b></DescItem>
        <DescItem label="Email"><b>{props.usuario?.email}</b></DescItem>
        <DescItem label="Rol"><Tag color={'blue'}>{props.usuario?.role}</Tag></DescItem>
      </Descriptions>
    </Drawer>
  );
}

export default UsuariosView;

