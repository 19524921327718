export const Consts = {
  APP: {
    googleMapsApiKey: 'AIzaSyCEIdGj-Ki2URrGU4jUgdwDDjgSLlGj2a4',
    THEME_KEY: 'THEME',
    SERVER_IP: '167.172.165.226:88',
  },
  PHONE: {
    CUPON: {
      ALTA: {
        TITULO: '¡Bienvenido!',
        DESCRIPCION: '¡Te damos la bienvenida a nuestra app! Como regalo de bienvenida tenes un descuento en tu proxima compra.'
      },
      CUMPLE: {
        TITULO: '¡Feliz cumpleaños!',
        DESCRIPCION: '¡En este dia tan especial queremos regalarte un descuento en tu proxima compra!.'
      }
    },
  },
  ROLES: {
    ADMIN: 'ADMIN',
    GERENTE: 'GERENTE',
    DISEÑADOR: 'DISEÑADOR',
  },
  PARAMETROS: {
    GEOLOCALIZACION: 'GEOLOCALIZACION',
  }
}