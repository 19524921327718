import AuthHelper from "../../../helpers/authHelper";
import Fetcher from "../../../helpers/fetcher";

const LoginApi = {
  login: (email, password) => {
    let form = new FormData();
    form.append('email', email);
    form.append('password', password);

    return Fetcher
      .post("auth/backoffice-user-login", form)
      .then(response => {
        AuthHelper.storeJwtToken(response.data.authorisation?.token);
        AuthHelper.storeUser(response.data.user);
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  },
}

export default LoginApi;