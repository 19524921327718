import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const StyledContainer = styled('div')`
  padding: 16px;
`

const StyledTransitionGroup = styled(TransitionGroup)`
  position: relative;
`

const StyledCSSTransition = styled(CSSTransition)`
  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit.fade-exit-active {
    opacity: 0;
  }

  &.slide-enter {
    opacity: 0;
    transform: translateX(500px);
  }
  
  &.slide-enter.slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.2s linear 0.4s;
  }
  
  &.slide-exit {
    opacity: 1;
    transform: translateX(0);
  }
  
  &.slide-exit.slide-exit-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(-500px);
    transition: all 0.2s linear;
  }
`

const StyledRouteSection = styled('section')`
  width: 100%;
  top: 0;
  left: 0;
`

export {
  StyledContainer as Container,
  StyledTransitionGroup as TransitionGroup,
  StyledCSSTransition as CSSTransition,
  StyledRouteSection as RouteSection,
}