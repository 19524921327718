
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import ParametrosList from "./components/parametrosList";
import ParametrosForm from "./components/parametrosForm";
 
const ParametrosPage = () => {
  return (
    <Routes>
      <Route index element={<ParametrosList />} />
      <Route path="/edit/:clave" element={<ParametrosForm />} />
    </Routes>
  );
}

export default ParametrosPage;