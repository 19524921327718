import { useEffect, useState } from "react";
import { notification } from "antd";
import axios from "axios";
import AuthHelper from "../helpers/authHelper";
import parser from "../helpers/parser";

const AxiosConfig = ({ children }) => {
  const [axiosReady, setAxiosReady] = useState(false);

  useEffect(() => {
    axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/`;

    axios.interceptors.request.use(config => {
      config.headers = AuthHelper.isJwtTokenStored() ? { ...config.headers, Authorization: `Bearer ${AuthHelper.getJwtToken()}` } : config.headers;
      return config;
    });

    axios.interceptors.response.use(
      response => {
        if (response.data.ValidationErrors) {
          let error = '';
          Object.keys(response.data.ValidationErrors).map(key => {
            error += response.data.ValidationErrors[key].join(' - ');
          });
          notification.error({ message: 'Error', description: "[Validacion Servidor] " + error, placement: 'bottomRight' });
          /*for (const validationType in response.data.ValidationErrors) {
            const validationMessage = response.data.ValidationErrors[validationType];
            notification.error({ message: 'Error', description: "[Validacion Servidor] " + validationMessage, placement: 'bottomRight' });
          }*/
          throw response;
        }
        return response;
      },
      error => {
        if (error.response) {
          console.debug(error.response);

          let message = parser.parseError(error);

          switch (error.response.status) {
            case 401:
              if (window.location?.href?.indexOf('/login') < 0) {
                AuthHelper.logout(() => {
                  window.location.replace("/login?status=expired");
                });
              }
              if (error.config?.url?.indexOf('backoffice-user-login') > -1) {
                notification.error({ message: 'Error', description: 'Usuario o clave incorrectos', placement: 'bottomRight' });
              }
              break;
            case 404:
              message = "[Endpoint No encontrado] " + error.response.config.baseURL + error.response.config.url;
              notification.error({ message: 'Error', description: message, placement: 'bottomRight' });
              break;
            default:
              notification.error({ message: 'Error', description: message, placement: 'bottomRight' });
          }
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    setAxiosReady(true);
  }, []);

  return axiosReady ? children : <></>
}

export default AxiosConfig;