import moment from 'moment';

export default class parser {
  static currency = text => {
    let num = parseFloat(text);
    return (
      '$' + num
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    );
  }

  static date = (text, format = 'DD/MM/YYYY') => {
    if (!text) { return ''; }
    if (typeof text === 'object') { return moment(text).format(format); }
    return !text.startsWith('0001-01-01') ? moment(text).format(format) : ''
  }

  static datetime = text => {
    if (!text) { return ''; }
    if (typeof text === 'object') { return moment(text).format('DD/MM/YYYY'); }
    return !text.startsWith('0001-01-01') ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''
  }

  static mask = text => {
    text = text.toString();
    return text ? text.substring(text.length / 2, text.length).padStart(text.length, '*') : '';
  }

  static parseError = error => {
    let err = error?.response ? error?.response : error;
    let message;
    try {
      if (typeof err === 'object') {
        if (err.data) {
          message = typeof err.data !== 'object'
            ? err.data
            : err.data.error ? err.data.error
              : err.data.ExceptionMessage ? err.data.ExceptionMessage
                : err.data.message ? err.data.message
                  : err.data.messages ? err.data.messages
                    : err.data.errorInfo ? err.data.errorInfo
                      : err.data.code && err.data.message ? err.data.code + ' - ' + err.data.message
                        : 'Ocurrio un error...';

          if (err.data.ValidationErrors) {
            message = '';
            Object.keys(err.data.ValidationErrors).map(key => {
              message += err.data.ValidationErrors[key].join(' - ');
            });
          }
        }
        else {
          message = err.message ? err.message : err.Message;
        }
      } else {
        message = err;
      }

      if (typeof message === 'object') {
        message = JSON.stringify(message);
      }
    } catch (e) {
      console.debug(e);
      message = 'Ocurrio un error...';
    }

    message = message == 'Unauthorized' ? 'Usuario o clave incorrecta' : message;
    message = message.length > 300 ? message.substring(0, 300) + '...' : message;

    return message;
  }

  static parseFileSize = size => {
    if (size > 0) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }
    return size + 'B';
  }
}