import Fetcher from "../../../../helpers/fetcher";

const ParametrosApi = {
  get: clave => {
    let url = '/parametros/';
    if (clave) { url += clave; }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  save: data => {
    return Fetcher
      .put('/parametros/' + data.id, data)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
}

export default ParametrosApi;