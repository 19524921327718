import { Col, Row } from "antd";
import DescCatItem from "./descCatItem";
import styled from "styled-components";
import { TbCaretLeft } from "react-icons/tb";

const StyledBackIcon = styled(TbCaretLeft)`
  color: black;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const Section = styled('div')`
  margin-bottom: 10px;
`;

const DescuentosCategoriaList = props => {
  return (
    props.descuentos?.length > 0 &&
    <Row gutter={[16, 0]}>
      <Col span={24}>
        <Section style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {props.showBack && <StyledBackIcon onClick={props.onBackClick} />}
          <h4 style={{ marginLeft: 8 }}>Descuentos en categorías</h4>
        </Section>
        <Row gutter={[16, 16]}>
          {props.descuentos?.map(descuento => (
            <Col span={24} key={descuento.id}>
              <DescCatItem
                descuento={descuento}
                onClick={() => props.setNavigation({ view: 'descCat', state: { id: descuento.id, } })}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default DescuentosCategoriaList;