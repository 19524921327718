import Fetcher from "../../../helpers/fetcher";

const PlantasApi = {
  get: id => {
    let url = '/plantas/';
    if (id) { url += id; }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  getGrupos: id => {
    let url = '/grupos/';
    if(id){ url += id; }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  save: data => {
    if (!data.id) {
      return Fetcher
        .post('/plantas', data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
    else {
      return Fetcher
        .put('/plantas/' + data.id, data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
  },
  delete: id => {
    return Fetcher
      .delete('/plantas/' + id, {})
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  cambiarOrden: (id, orden) => {
    return Fetcher
      .put('/plantas/cambiar-orden/' + id, { orden: orden })
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  uploadFile: (formdata) => {
    formdata.append('entity', 'PLANTA');

    return Fetcher
      .post('/assets', formdata)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  deleteFile: (id) => {
    return Fetcher
      .delete('/assets/' + id)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
}

export default PlantasApi;