
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import DescuentosClubForm from "./components/descuentosClubForm";
import DescuentosClubList from "./components/descuentosClubList";
import DescuentosClubView from "./components/descuentosClubView";
 
const DescuentosClubPage = () => {
  return (
    <Routes>
      <Route index element={<DescuentosClubList />} />
      {/*<Route path="/new" element={<DescuentosClubForm />} />*/}
      <Route path="/edit/:id" element={<DescuentosClubForm />} />
      <Route path="/view/:id" element={<DescuentosClubView />} />
    </Routes>
  );
}

export default DescuentosClubPage;