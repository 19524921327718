import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import LoginContainer from '../login/LoginContainer';
import AuthHelper from '../../helpers/authHelper';
import { AppRoutes } from './routes';
import styled from "styled-components";
import { Layout } from "antd";
import AppHeader from "./header/AppHeader";
import AppContent from "./content/AppContent";
import AppFooter from "./footer/AppFooter";
import { useState } from "react";
import AppLeftMenu from "./leftMenu/AppLeftMenu";
import ForgotPassContainer from "../forgot-password/ForgotPassContainer";

const ProtectedRoute = props => {
  if (!AuthHelper.isUserAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return props.children;
}

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`

const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <StyledLayout>
      <Router>
        <Routes>

          <Route path="/*"
            element={(
              <ProtectedRoute>
                <AppLeftMenu
                  routes={AppRoutes}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                />
                <Layout>
                  <AppHeader
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                  />
                  <AppContent routes={AppRoutes} />
                  <AppFooter />
                </Layout>
              </ProtectedRoute>
            )}
          />

          <Route path="/login/*" element={<LoginContainer />} />
          <Route path="/forgot-password/*" element={<ForgotPassContainer />} />

          <Route path="*" element={<Navigate push to="/login" />} />

        </Routes>
      </Router>
    </StyledLayout>
  );
}

export default AppLayout;