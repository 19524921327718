import Fetcher from "../../../helpers/fetcher";

const UsuariosApi = {
  get: id => {
    let url = '/usuarios/backoffice/';
    if (id) { url += id; }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  getLocalidades: search => {
    let url = '/localidades?search=' + search;
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  save: data => {
    if (!data.id) {
      return Fetcher
        .post('/auth/backoffice-user-registration', data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
    else {
      return Fetcher
        .put('/usuarios/backoffice/' + data.id, data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
  },
  delete: id => {
    return Fetcher
      .delete('/usuarios/backoffice/' + id, {})
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },

  changePassword: (idUser, pass, newPass) => {
    let data = {
      password: pass,
      newpassword: newPass
    }
    return Fetcher
      .post('/usuarios/backoffice' + idUser + '/change-password/', data)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },

  resetPassword: idUser => {
    return Fetcher
      .post('/usuarios/backoffice/' + idUser + '/reset-password/')
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
}

export default UsuariosApi;