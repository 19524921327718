
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import DescuentosArticuloForm from "./components/descuentosArticuloForm";
import DescuentosArticuloList from "./components/descuentosArticuloList";
import DescuentosArticuloView from "./components/descuentosArticuloView";
 
const DescuentosArticuloPage = () => {
  return (
    <Routes>
      <Route index element={<DescuentosArticuloList />} />
      {/*<Route path="/new" element={<DescuentosArticuloForm />} />*/}
      <Route path="/edit/:id" element={<DescuentosArticuloForm />} />
      <Route path="/view/:id" element={<DescuentosArticuloView />} />
    </Routes>
  );
}

export default DescuentosArticuloPage;