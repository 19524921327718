import { Tag } from "antd";
import { useEffect, useState } from "react";
import { DefaultTheme } from '../../../styles/AppTheme';

const NotifStatusTag = props => {
  const [color, setColor] = useState('success');
  const [estado, setEstado] = useState('PENDIENTE');

  useEffect(() => {
    switch (props.status?.toUpperCase()) {
      case 'INGRESADA':
        setColor(DefaultTheme.colors.orange);
        setEstado('PENDIENTE');
        break;
      case 'ENVIANDO':
        setColor(DefaultTheme.colors.primary);
        setEstado('ENVIANDO');
        break;
      case 'ERROR':
        setColor('error');
        setEstado('ERROR');
        break;
      case 'CANCELADO':
        setColor(DefaultTheme.colors.grey3);
        setEstado('CANCELADO');
        break;
      case 'FINALIZADO':
        setColor('success');
        setEstado('FINALIZADO');
        break;
      default: break;
    }
  }, [props.status]);

  return (
    <Tag color={color}>{estado}</Tag>
  )
}

export default NotifStatusTag;