import React, { useEffect, useState } from "react";
import { Spin, Row, Col, Tabs, Descriptions } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CuponesApi from "../api/cuponesApi";
import MediaTable from "../../../components/media/mediaTable";
import PageContainer from "../../../components/pageContainer";
import parser from "../../../helpers/parser";
import UploadButton from "../../../components/buttons/uploadButton";
import EditButton from "../../../components/buttons/editButton";
import CuponesForm from "./cuponesForm";

const { TabPane } = Tabs;
const DescItem = Descriptions.Item;

const CuponesView = props => {
  const navigate = useNavigate();
  const params = useParams();
  const [tipo, setTipo] = useState(undefined);
  const [showCuponForm, setShowCuponForm] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadCupon();
  }, []);

  const loadCupon = async () => {
    setWorking(true);
    try {
      const response = await CuponesApi.get(params.id);
      setTipo(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title={`Cupón: ${tipo?.tipo_cupon || ''}`}
      onBack={() => navigate(-1)}
      extra={<EditButton onClick={() => setShowCuponForm(true)} />}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Descriptions size="small" column={{ lg: 2, md: 1, sm: 1 }} bordered>
              <DescItem label="Título" span={2}><b>{tipo?.titulo}</b></DescItem>
              <DescItem label="Descripción" span={2}><b>{tipo?.descripcion}</b></DescItem>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Descriptions size="small" column={{ lg: 2, md: 1, sm: 1 }} bordered>
              <DescItem label="Tipo"><b>{tipo?.tipo_cupon}</b></DescItem>
              <DescItem label="Descuento"><b>{tipo?.descuento}%</b></DescItem>
              <DescItem label="Validez previa"><b>{tipo?.validez_previa}</b></DescItem>
              <DescItem label="Validez posterior"><b>{tipo?.validez_posterior}</b></DescItem>
              <DescItem label="Creado"><b>{parser.datetime(tipo?.created_at)}</b></DescItem>
              <DescItem label="Actualizado"><b>{parser.datetime(tipo?.updated_at)}</b></DescItem>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Tabs
              defaultActiveKey={['Archivos']}
              type="editable-card"
              hideAdd
            >
              <TabPane
                key="Archivos"
                tab={<>Archivos <small>({tipo?.assets?.length})</small></>}
                closeIcon={
                  <UploadButton
                    btnType="link"
                    uploadFile={(data) => CuponesApi.uploadFile(data)}
                    onUpload={() => loadCupon()}
                    entity_id={tipo?.id}
                    filetypes={'image/jpeg, image/jpg, image/png, image/gif'}
                    disabled={tipo?.assets?.length > 0}
                  />
                }
              >
                <MediaTable
                  files={tipo?.assets}
                  onDelete={async media => {
                    await CuponesApi.deleteFile(media.id);
                    loadCupon();
                  }}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Spin>

      <CuponesForm
        open={showCuponForm}
        onClose={() => setShowCuponForm(false)}
        cupon={tipo}
        loadCupon={loadCupon}
      />
    </PageContainer>
  );
}

export default CuponesView;

