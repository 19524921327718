import { PageHeader } from "@ant-design/pro-layout";
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card } from "antd";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

const StyledContainer = styled('div')`
  padding: 14px;
`

const PageContainer = props => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Card>
      {props.title &&
        <PageHeader
          title={props.title}
          onBack={props.onBack}
          extra={
            props.extra
              ? props.extra
              : props.defaultExtra && [
                <Button key="1" type="primary" icon={<PlusOutlined />} onClick={() => navigate(location.pathname + '/new')}>
                  Nuevo
                </Button>,
              ]
          }
        />}
      <StyledContainer>
        {props.children}
      </StyledContainer>
    </Card>
  )
}

export default PageContainer;