import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../assets/logo.png';
import logoCollapsed from '../assets/appicon.png';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import AuthHelper from '../../../helpers/authHelper';
import { DefaultTheme } from '../../../styles/AppTheme';

const { Sider } = Layout;

const StyledLogo = styled('div')`
  height: 64px;
  margin: 16px;
  display: flex;
  justify-content: center;

  img {
    width: ${props => props.collapsed
    ? props.theme.components?.Menu?.logo?.widthCollapsed
    : props.theme.components?.Menu?.logo?.width}px;
    height: ${props => props.collapsed
    ? props.theme.components?.Menu?.logo?.heightCollapsed
    : props.theme.components?.Menu?.logo?.height}px;
  }
`

const StyledMenu = styled(Menu)`
  &.ant-menu-light .ant-menu-item-selected {
    background-color: ${props => DefaultTheme.components.Menu.itemSelectedBg} !important;
  }
`;

const AppLeftMenu = props => {
  const location = useLocation();
  const [user] = useState(AuthHelper.getUser());
  const { windowWidth } = useWindowDimensions();
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState(undefined);
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    if (location) {
      initMenu();
    }
  }, [location]);

  useEffect(() => {
    if (props.collapsed === false) {
      initMenu();
    }
  }, [props.collapsed]);

  const findRoute = (key, items) => {
    if (!items) { return; }
    let item = items.find(x => x.key === key);
    if (item) {
      return item;
    }
    else {
      let itemsWithChildren = items.filter(x => x.children?.length > 0);
      const results = itemsWithChildren.map(item => {
        return findRoute(key, item.children);
      });
      return results.length > 0 ? results[0] : results;
    }
  }

  const initMenu = () => {
    const loc = location.pathname.split('?').shift() || '';
    let firstLoc = loc
    if (firstLoc != '/') {
      firstLoc = '/' + loc.split('/').filter(x => x)[0];
    }
    const route = findRoute(firstLoc, props.routes);
    if (route) {
      setOpenKeys([route.parent]);
      setSelectedKeys([firstLoc]);
    }
  }

  return (
    <Sider
      collapsible
      collapsed={props.collapsed}
      collapsedWidth={windowWidth > 600 ? 80 : 0}
      trigger={null}
      breakpoint="lg"
      onBreakpoint={broken => {
        props.setCollapsed(broken);
      }}
    >
      <StyledLogo collapsed={props.collapsed}>
        <img src={props.collapsed ? logoCollapsed : logo} alt="logo" />
      </StyledLogo>

      <StyledMenu
        defaultSelectedKeys={[props.defaultMenuItem ? props.defaultMenuItem : '/']}
        openKeys={openKeys}
        onOpenChange={keys => setOpenKeys([...keys])}
        selectedKeys={selectedKeys}
        mode="inline"
        onSelect={() => windowWidth <= 600 ? props.setCollapsed(true) : ''}
        onClick={opt => {
          let route = findRoute(opt.key, props.routes);
          navigate(route.link);
        }}
        items={props.routes.filter(x => (x.link || x.children) && x.roles.includes(user?.role))}
      />
    </Sider>
  );
}

export default AppLeftMenu;
