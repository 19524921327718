import { Input } from "antd";
import styled from "styled-components";

const StyledInput = styled(Input)`
  min-width: 200px;
`

const TableSearch = props => {
  return (
    <StyledInput
      {...props}
      placeholder={props.placeholder || "Buscar"}
      allowClear
      onChange={props.onChange}
      //size="small"
    />
  )
}

export default TableSearch;