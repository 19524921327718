import React, { useState } from "react";
import { Button, Form, Input, notification, Spin, Modal } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import { FormConfig } from "../../../helpers/config";
import CuponesApi from "../api/cuponesApi";

const { TextArea } = Input;
const FormItem = Form.Item;

const CuponesForm = props => {
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async values => {
    setWorking(true);
    try {
      let data = {
        ...props.cupon,
        ...values,
      }

      await CuponesApi.save(data);
      notification.success({ message: 'Exito', description: 'Cupón actualizado correctamente', placement: 'bottomRight' });
      await props.loadCupon();
      props.onClose();
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  };

  return (
    <Modal
      title={'Editar cupón'}
      width="60%"
      open={props.open}
      onCancel={props.onClose}
      footer={[
        <Button key="ok" type="primary" htmlType="submit" icon={<SaveOutlined />} onClick={() => form.submit()}>Guardar</Button>,
        <Button key="back" onClick={props.onClose}>Cerrar</Button>
      ]}
    >
      <Spin spinning={working}>
        <Form
          {...FormConfig.DefaultLayout}
          form={form}
          name="formulario"
          onFinish={onFinish}
          initialValues={{ ...props.cupon }}
          scrollToFirstError
        >
          <FormItem name="titulo" label="Titulo">
            <Input maxLength={255} showCount />
          </FormItem>

          <FormItem name="descripcion" label="Descripción">
            <TextArea rows={6} maxLength={255} showCount />
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
}

export default CuponesForm;

