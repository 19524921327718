import styled from "styled-components";
import { DefaultTheme } from "../../../../styles/AppTheme";
import parser from "../../../../helpers/parser";

const Container = styled('div')`
  border-radius: 10px;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const ContainerRight = styled('div')`
  width: 100px;
  color: white;
  background-color: ${props => DefaultTheme.colors.black};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
`;

const DescMontoItem = props => {
  return (
    <Container onClick={props.onClick}>
      <div style={{ flex: 1, padding: '10px', display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontSize: 10 }}>Superando los</span>
        <span style={{ fontSize: 12, fontWeight: 'bold' }}>{parser.currency(props.descuento?.monto_desde)}</span>
        {props.descuento?.solo_efectivo && <span style={{ fontSize: 10 }}>Sólo efectivo</span>}
      </div>
      <ContainerRight>
        <span style={{ fontSize: 24, fontWeight: 'bold' }}>{props.descuento?.descuento}%</span>
        <span style={{ fontSize: 10 }}>DE DESCUENTO</span>
      </ContainerRight>
    </Container>
  )
}

export default DescMontoItem;