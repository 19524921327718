
import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import DescuentosMontoForm from "./components/descuentosMontoForm";
import DescuentosMontoList from "./components/descuentosMontoList";
import DescuentosMontoView from "./components/descuentosMontoView";
 
const DescuentosMontoPage = () => {
  return (
    <Routes>
      <Route index element={<DescuentosMontoList />} />
      {/*<Route path="/new" element={<DescuentosMontoForm />} />*/}
      <Route path="/edit/:id" element={<DescuentosMontoForm />} />
      <Route path="/view/:id" element={<DescuentosMontoView />} />
    </Routes>
  );
}

export default DescuentosMontoPage;