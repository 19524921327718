import React, { useEffect, useState } from "react";
import { Descriptions, Card, Drawer, Space, Tag, Input, Form, notification, Spin, Button, Row } from "antd";
import AuthHelper from "../../helpers/authHelper";
import parser from "../../helpers/parser";
import { FormConfig } from "../../helpers/config";
import { SaveOutlined } from '@ant-design/icons';
import UsuariosApi from "../../pages/usuarios/api/usuariosApi";

const FormItem = Form.Item;
const DescItem = Descriptions.Item;

const PerfilView = props => {
  const [form] = Form.useForm();
  const [user] = useState(AuthHelper.getUser());
  const [working, setWorking] = useState(false);

  useEffect(() => {
    if (props.open !== true) { return; }
  }, [props.open]);

  const onFinish = async values => {
    if (values.password !== values.newpassword) {
      notification.error({ message: 'Error', description: 'Los password deben ser iguales', placement: 'bottomRight' });
      return;
    }

    setWorking(true);
    try {
      let data = {
        id: user?.id,
        ...values,
      }

      await UsuariosApi.save(data);
      notification.success({ message: 'Exito', description: 'Contraseña modificada correctamente', placement: 'bottomRight' });
      form.resetFields();
      props.onClose();
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  };

  return (
    <Drawer
      title={`Mi Perfil`}
      placement="right"
      closable={true}
      onClose={props.onClose}
      open={props.open}
      width={600}
    >
      <Spin spinning={working}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Descriptions size="small" column={1} bordered>
            <DescItem label="Email"><b>{user?.email}</b></DescItem>
            <DescItem label="Rol"><Tag color={'blue'}>{user?.role}</Tag></DescItem>
            <DescItem label="Nombre"><b>{user?.name}</b></DescItem>
            <DescItem label="DNI"><b>{user?.dni}</b></DescItem>
            <DescItem label="Nombre completo"><b>{user?.nombre_completo}</b></DescItem>
            <DescItem label="Localidad"><b>{user?.localidad}</b></DescItem>
            <DescItem label="Código postal"><b>{user?.codigo_postal}</b></DescItem>
            <DescItem label="Teléfono"><b>{user?.telefono}</b></DescItem>
            <DescItem label="Fecha nacimiento"><b>{parser.date(user?.fecha_nacimiento)}</b></DescItem>
            <DescItem label="Fecha alta"><b>{parser.date(user?.fecha_alta)}</b></DescItem>
          </Descriptions>

          <Card type="inner" title="Cambiar clave">
            <Form
              {...FormConfig.DefaultLayout}
              form={form}
              name="formulario"
              onFinish={onFinish}
              scrollToFirstError
            >
              <FormItem name="password" label="Password"
                rules={[
                  ...FormConfig.DefaultRules,
                  { min: 6, message: 'El password debe tener al menos 6 caracteres' }
                ]}
              >
                <Input.Password />
              </FormItem>

              <FormItem name="newpassword" label="Repetir password"
                rules={[
                  ...FormConfig.DefaultRules,
                  { min: 6, message: 'El password debe tener al menos 6 caracteres' }
                ]}
              >
                <Input.Password />
              </FormItem>

              <FormItem wrapperCol={{ span: 24 }}>
                <Row justify={'end'}>
                  <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Guardar</Button>
                </Row>
              </FormItem>
            </Form>
          </Card>
        </Space>
      </Spin>
    </Drawer>
  );
}

export default PerfilView;
