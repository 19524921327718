import React, { useState } from "react";
import PageContainer from "../../../components/pageContainer";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";

const ParametricasList = props => {
  const navigate = useNavigate();
  const [parametricas] = useState([
    { name: 'grupos', title: 'Grupos/Sectores' },
    { name: 'rubros', title: 'Rubros' },
    { name: 'parametros', title: 'Parámetros' },
  ]);

  return (
    <PageContainer
      title="Paramétricas"
    >
      <Card>
        {parametricas.map(prm => (
          <Card.Grid
            key={prm.name}
            onClick={() => navigate('./' + prm.name)}
            style={{ cursor: 'pointer', textAlign: 'center' }}
          >
            <span><b>{prm.title}</b></span>
          </Card.Grid>
        ))}
      </Card>
    </PageContainer>
  );
}

export default ParametricasList;