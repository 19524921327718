import Fetcher from "../../../helpers/fetcher";

const BannersApi = {
  get: id => {
    let url = '/banners/';
    if (id) { url += id; }
    return Fetcher
      .get(url)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  save: data => {
    if (!data.id) {
      return Fetcher
        .post('/banners', data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
    else {
      return Fetcher
        .put('/banners/' + data.id, data)
        .then((response) => { return response.data; })
        .catch((error) => { throw error; });
    }
  },
  delete: id => {
    return Fetcher
      .delete('/banners/' + id, {})
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  publicar: id => {
    return Fetcher
      .get('/banners/publicar-banner/' + id)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  despublicar: id => {
    return Fetcher
      .get('/banners/despublicar-banner/' + id)
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
  cambiarOrden: (id, orden) => {
    return Fetcher
      .put('/banners/cambiar-orden/' + id, { orden: orden })
      .then((response) => { return response.data; })
      .catch((error) => { throw error; });
  },
}

export default BannersApi;