import React, { useState } from "react";
import { Space, Table } from "antd";
import GruposDetail from "./gruposDetail";
import ViewButton from "../../../../components/buttons/viewButton";

const GruposTable = props => {
  const [selectedGrupo, setSelectedGrupo] = useState(undefined);
  const [showGrupoDetail, setShowGrupoDetail] = useState(false);
  const [working, setWorking] = useState(false);

  const columns = [
    { title: 'Número', dataIndex: 'numero_grupo', align: 'center' },
    { title: 'Descripción', dataIndex: 'descripcion', },
    {
      title: '', dataIndex: 'acciones', width: '10%', align: 'center',
      render: (text, record) => <Space>
        <ViewButton onClick={() => { setSelectedGrupo(record); setShowGrupoDetail(true) }} />
      </Space>
    },
  ];

  return (
    <>
      <Table
        size="small"
        loading={working}
        columns={columns}
        dataSource={props.grupos}
        rowKey={record => record.id}
        pagination={{ hideOnSinglePage: true, pageSize: 10, showSizeChanger: false }}
        scroll={{ x: '50%' }}
      />
      <GruposDetail
        open={showGrupoDetail}
        onClose={() => setShowGrupoDetail(false)}
        grupo={selectedGrupo}
      />
    </>
  );
}

export default GruposTable;