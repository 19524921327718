import React, { useEffect, useState } from "react";
import { Spin, Row, Col, Tabs, Descriptions } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import DescuentosMontoApi from "../api/descuentosMontoApi";
import MediaTable from "../../../components/media/mediaTable";
import PageContainer from "../../../components/pageContainer";
import UploadButton from "../../../components/buttons/uploadButton";
import parser from "../../../helpers/parser";
import DescuentosMontoForm from "./descuentosMontoForm";
import EditButton from "../../../components/buttons/editButton";

const { TabPane } = Tabs;
const DescItem = Descriptions.Item;

const DescuentosMontoView = props => {
  const navigate = useNavigate();
  const params = useParams();
  const [descuento, setDescuento] = useState(undefined);
  const [showDescuentoForm, setShowDescuentoForm] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadDescuento();
  }, []);

  const loadDescuento = async () => {
    setWorking(true);
    try {
      const response = await DescuentosMontoApi.get(params.id);
      setDescuento(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title={`Descuento monto: 
        ${descuento?.descuento ? descuento?.descuento + '%' : ''}
        ${descuento?.monto_desde ? ' - ' + parser.currency(descuento?.monto_desde) : ''}`}
      onBack={() => navigate(-1)}
      extra={<EditButton onClick={() => setShowDescuentoForm(true)} />}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Descriptions size="small" column={1} bordered>
              <DescItem label="Título"><b>{descuento?.titulo}</b></DescItem>
              <DescItem label="Descripción"><b>{descuento?.descripcion}</b></DescItem>
              <DescItem label="Info extra"><b>{descuento?.extra_1}</b></DescItem>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Descriptions size="small" column={1} bordered>
              <DescItem label="Descuento"><b>{descuento?.descuento}%</b></DescItem>
              <DescItem label="Monto desde"><b>{parser.currency(descuento?.monto_desde)}</b></DescItem>
              <DescItem label="Sólo efectivo"><b>{descuento?.solo_efectivo == true ? 'SI' : 'NO'}</b></DescItem>
              <DescItem label="Creado"><b>{parser.date(descuento?.created_at)}</b></DescItem>
              <DescItem label="Actualizado"><b>{parser.date(descuento?.updated_at)}</b></DescItem>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Tabs
              defaultActiveKey={['Archivos']}
              type="editable-card"
              hideAdd
            >
              <TabPane
                key="Archivos"
                tab={<>Archivos <small>({descuento?.assets?.length})</small></>}
                closeIcon={
                  <UploadButton
                    btnType="link"
                    uploadFile={(data) => DescuentosMontoApi.uploadFile(data)}
                    onUpload={() => loadDescuento()}
                    entity_id={descuento?.id}
                    filetypes={'image/jpeg, image/jpg, image/png, image/gif'}
                    disabled={descuento?.assets?.length > 0}
                  />
                }
              >
                <MediaTable
                  files={descuento?.assets}
                  onDelete={async media => {
                    await DescuentosMontoApi.deleteFile(media.id);
                    loadDescuento();
                  }}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Spin>

      <DescuentosMontoForm
        open={showDescuentoForm}
        onClose={() => setShowDescuentoForm(false)}
        descuento={descuento}
        loadDescuento={loadDescuento}
      />
    </PageContainer>
  );
}

export default DescuentosMontoView;

