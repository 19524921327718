import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import _ from 'lodash';
import moment from 'moment';
import { Spin } from 'antd';
import ClientesApi from '../../clientes/api/clientesApi';

const ClientesChart = props => {
  const [chartData, setChartData] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadInfo();
  }, []);

  const loadInfo = async () => {
    setWorking(true);
    try {
      let response = await ClientesApi.getClientesActivos();
      response = Object.keys(response).sort().reduce(
        (obj, key) => {
          obj[key] = response[key];
          return obj;
        },
        {}
      );

      let chartDataAux = [];
      let date;
      let total = 0;
      Object.keys(response).map(key => {
        date = moment(key, 'YYYY-MM');
        total += response[key];
        return chartDataAux.push({
          anioValue: date.year(),
          mesValue: date.month(),
          mes: date.format('MM-YYYY'),
          cantidad: total,
        });
      });
      setChartData(_.sortBy(chartDataAux, 'type'));
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }


  const config = {
    data: chartData,
    xField: 'mes',
    yField: 'cantidad',
    label: {},
    smooth: true,
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };
  return <Spin spinning={working}>
    <Line {...config} />
  </Spin>;
};

export default ClientesChart;