import React from "react";
import { Space, Table } from "antd";
import { useNavigate } from "react-router-dom";
import ViewButton from "../../../components/buttons/viewButton";
import ImagePreview from "../../../components/ImagePreview";

const CuponesTable = props => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Imagen (500/400)', dataIndex: 'file', align: 'center',
      render: (text, record) => (
        record.assets?.length > 0 ? <ImagePreview file={record.assets[0]?.file} /> : ''
      )
    },
    { title: 'Tipo', dataIndex: 'tipo_cupon', align: 'center' },
    { title: 'Titulo', dataIndex: 'titulo', },
    {
      title: 'Descuento', dataIndex: 'descuento', align: 'center',
      render: text => <span>{text}%</span>
    },
    { title: 'Validez previa', dataIndex: 'validez_previa', align: 'center' },
    { title: 'Validez posterior', dataIndex: 'validez_posterior', align: 'center' },
    {
      title: '', dataIndex: '', align: 'center', width: '5%',
      render: (text, record) => (
        <Space>
          <ViewButton onClick={() => navigate('./view/' + record.id)} />
        </Space>
      )
    },
  ];

  return (
    <Table
      size="small"
      loading={props.working}
      columns={columns}
      dataSource={props.cupones?.sort((a, b) => a.tipo_cupon - b.tipo_cupon)}
      rowKey={record => record.id}
      pagination={{ hideOnSinglePage: true, pageSize: 10 }}
      scroll={{ x: '50%' }}
    />
  );
}

export default CuponesTable;