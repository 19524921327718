import React, { useEffect, useState } from "react";
import { Tag, Descriptions, Spin, Row, Col, Tabs, Space } from "antd";
import PageContainer from "../../../components/pageContainer";
import { useNavigate, useParams } from "react-router-dom";
import PlantasApi from "../api/plantasApi";
import UploadButton from "../../../components/buttons/uploadButton";
import MediaTable from "../../../components/media/mediaTable";
import EditButton from "../../../components/buttons/editButton";

const DescItem = Descriptions.Item;
const TabPane = Tabs.TabPane;

const PlantasView = props => {
  const navigate = useNavigate();
  const params = useParams();
  const [planta, setPlanta] = useState(undefined);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadPlanta();
  }, []);

  const loadPlanta = async () => {
    setWorking(true);
    try {
      const response = await PlantasApi.get(params.id);
      setPlanta(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  return (
    <PageContainer
      title={`Planta: ${planta?.titulo || ''}`}
      onBack={() => navigate(-1)}
      extra={<EditButton onClick={() => navigate('/plantas/edit/' + planta?.id)} />}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Descriptions size="small" column={1} bordered>
              <DescItem label="Iniciales"><b>{planta?.iniciales}</b></DescItem>
              <DescItem label="Titulo"><b>{planta?.titulo}</b></DescItem>
              <DescItem label="Subtitulo"><b>{planta?.subtitulo}</b></DescItem>
              <DescItem label="Descripción"><b>{planta?.descripcion}</b></DescItem>
              <DescItem label="Grupos">
                <Space>
                  {planta?.grupos?.map(grupo => (
                    <Tag color="blue">{grupo.descripcion}</Tag>
                  ))}
                </Space>
              </DescItem>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Tabs
              defaultActiveKey={['Archivos']}
              type="editable-card"
              hideAdd
            >
              <TabPane
                key="Archivos"
                tab={<>Archivos <small>({planta?.assets?.length})</small></>}
                closeIcon={
                  <UploadButton
                    btnType="link"
                    uploadFile={(data) => PlantasApi.uploadFile(data)}
                    onUpload={() => loadPlanta()}
                    entity_id={planta?.id}
                    filetypes={'image/jpeg, image/jpg, image/png, image/gif'}
                    disabled={planta?.assets?.length > 0}
                  />
                }
              >
                <MediaTable
                  files={planta?.assets}
                  onDelete={async media => {
                    await PlantasApi.deleteFile(media.id);
                    loadPlanta();
                  }}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Spin>
    </PageContainer>
  );
}

export default PlantasView;

