import { FaHome, FaUsers, FaDatabase, FaGift, FaTags, FaDollarSign, FaPhotoVideo, FaBuilding, FaBox, FaBell, FaTicketAlt } from "react-icons/fa"
import DashboardPage from '../dashboard/dashboardPage';
import UsuariosPage from "../usuarios/usuariosPage";
import ParametricasPage from "../parametricas/parametricasPage";
import DescuentosClubPage from "../descuentos_club/descuentosClubPage";
import DescuentosMontoPage from "../descuentos_monto/descuentosMontoPage";
import ClientesPage from "../clientes/clientesPage";
import CuponesPage from "../cupones/cuponesPage";
import BannersPage from "../banners/bannersPage";
import PlantasPage from "../plantas/plantasPage";
import { Consts } from "../../helpers/consts";
import DescuentosArticuloPage from "../descuentos_articulo/descuentosArticuloPage";
import NotificacionesPage from "../notificaciones/notificacionesPage";

export const AppRoutes = [
  {
    key: '/',
    icon: <FaHome />,
    link: '/',
    path: '/*',
    label: 'Home',
    title: 'Home',
    element: <DashboardPage />,
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
  },
  {
    key: '/clientes',
    icon: <FaUsers />,
    link: '/clientes',
    path: '/clientes/*',
    label: 'Clientes',
    title: 'Clientes',
    element: <ClientesPage />,
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE],
  },
  {
    key: '/cupones',
    icon: <FaTicketAlt />,
    link: '/cupones',
    path: '/cupones/*',
    label: 'Cupones',
    title: 'Cupones',
    element: <CuponesPage />,
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
  },
  {
    key: '/plantas',
    icon: <FaBuilding />,
    link: '/plantas',
    path: '/plantas/*',
    label: 'Plantas',
    title: 'Plantas',
    element: <PlantasPage />,
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
  },
  {
    key: '/banners',
    icon: <FaPhotoVideo />,
    link: '/banners',
    path: '/banners/*',
    label: 'Banners',
    title: 'Banners',
    element: <BannersPage />,
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
  },
  /*{
    key: '/ofertas',
    icon: <FaMoneyCheckAlt />,
    link: '/ofertas',
    path: '/ofertas/*',
    label: 'Ofertas',
    title: 'Ofertas',
    element: <OfertasPage />,
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
  },*/
  {
    key: '/descuentos',
    icon: <FaTags />,
    label: 'Descuentos',
    title: 'Descuentos',
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
    children: [
      {
        parent: '/descuentos',
        key: '/descuentos-club',
        icon: <FaTags />,
        link: '/descuentos-club',
        path: '/descuentos-club/*',
        label: 'Globales',
        title: 'Globales',
        element: <DescuentosClubPage />,
        roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
      },
      {
        parent: '/descuentos',
        key: '/descuentos-monto',
        icon: <FaDollarSign />,
        link: '/descuentos-monto',
        path: '/descuentos-monto/*',
        label: 'Monto',
        title: 'Monto',
        element: <DescuentosMontoPage />,
        roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
      },
      {
        parent: '/descuentos',
        key: '/descuentos-articulo',
        icon: <FaBox />,
        link: '/descuentos-articulo',
        path: '/descuentos-articulo/*',
        label: 'Articulo',
        title: 'Articulo',
        element: <DescuentosArticuloPage />,
        roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
      },
    ]
  },
  {
    key: '/notificaciones',
    icon: <FaBell />,
    link: '/notificaciones',
    path: '/notificaciones/*',
    label: 'Notificaciones',
    title: 'Notificaciones',
    element: <NotificacionesPage />,
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
  },
  /*{
    key: '/configuracion',
    icon: <FaWrench />,
    link: '/configuracion',
    path: '/configuracion/*',
    label: 'Configuración',
    title: 'Configuración',
    element: <ConfiguracionPage />,
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE, Consts.ROLES.DISEÑADOR],
  },*/
  {
    key: '/usuarios',
    icon: <FaUsers />,
    link: '/usuarios',
    path: '/usuarios/*',
    label: 'Usuarios',
    title: 'Usuarios',
    element: <UsuariosPage />,
    roles: [Consts.ROLES.ADMIN],
  },
  {
    key: '/parametricas',
    icon: <FaDatabase />,
    link: '/parametricas',
    path: '/parametricas/*',
    label: 'Paramétricas',
    title: 'Paramétricas',
    element: <ParametricasPage />,
    roles: [Consts.ROLES.ADMIN, Consts.ROLES.GERENTE],
  },
] 