import styled from "styled-components";
import Utils from "../../../helpers/utils";

const StyledWidget = styled('div')`
  background-color: ${props => props.bgColor};
  border-radius: 10px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:hover { 
    transform: scale(1.05); 
  }
`;

const Content = styled('div')`
  display: flex;
`;

const Icon = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 40px;
  background-color: ${props => Utils.colorLuminance(props.bgColor, 0.1)};
  color: ${props => props.textColor || '#fff'};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const Data = styled('div')`
  flex: 1;
  padding: 20px;
  color: ${props => props.textColor || '#fff'};
`;

const Title = styled('div')`
  font-size: 24px;
`;

const Message = styled('div')`
  font-size: 14px;
`;

const Widget = props => {
  return (
    <StyledWidget
      bgColor={props.bgColor}
      onClick={props.onClick}
    >
      <Content>
        <Icon
          bgColor={props.bgColor}
          textColor={props.textColor}
        >
          {props.icon}
        </Icon>
        <Data
          textColor={props.textColor}
        >
          {props.title != undefined && <Title><b>{props.title}</b></Title>}
          {props.message != undefined && <Message>{props.message}</Message>}
        </Data>
      </Content>
    </StyledWidget>
  )
}

export default Widget;