import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { Button, Form, Card, Input, notification, Spin, Switch } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import { PageHeader } from "@ant-design/pro-layout";
import ParametrosApi from "../api/parametrosApi";
import { FormConfig } from "../../../../helpers/config";
import { Consts } from "../../../../helpers/consts";

const FormItem = Form.Item;

const ParametrosForm = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [parametro, setParametro] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      loadParametro(params.clave);
    }
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [parametro]);

  const loadParametro = async clave => {
    setWorking(true);
    try {
      const response = await ParametrosApi.get(clave);
      setParametro(response);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  }

  const onFinish = async values => {
    setWorking(true);

    try {
      let data = {
        id: parametro?.id,
        ...values,
        valor: values.valor === true ? 'True' : 'False',
      }

      await ParametrosApi.save(data);
      notification.success({ message: 'Exito', description: 'Parámetro guardado correctamente', placement: 'bottomRight' });
      navigate(-1);
    }
    catch (error) {
      console.debug(error);
    }
    setWorking(false);
  };

  return (
    <Card>
      <PageHeader
        title={`${parametro && parametro.id ? 'Editar parámetro' : 'Nuevo parámetro'}`}
        /*subTitle="Formulario"*/
        onBack={() => navigate(-1)}
      />
      <Spin spinning={working}>
        <Card>
          <Form
            {...FormConfig.DefaultLayout}
            form={form}
            name="formulario"
            onFinish={onFinish}
            initialValues={{
              ...parametro,
              valor: parametro?.clave === Consts.PARAMETROS.GEOLOCALIZACION
                ? (parametro?.valor === 'True' ? true : false)
                : parametro?.valor
            }}
            scrollToFirstError
            autoComplete="off"
          >
            <FormItem name="clave" label="Clave" rules={[...FormConfig.DefaultRules]}>
              <Input disabled />
            </FormItem>

            <FormItem name="valor" label="Valor" rules={[...FormConfig.DefaultRules]}
              valuePropName={parametro?.clave === Consts.PARAMETROS.GEOLOCALIZACION ? 'checked' : 'value'}
            >
              {parametro?.clave === Consts.PARAMETROS.GEOLOCALIZACION
                ? <Switch />
                : <Input disabled />}
            </FormItem>

            <FormItem name="descripcion" label="Descripcion" rules={[...FormConfig.DefaultRules]}>
              <Input disabled />
            </FormItem>

            <FormItem wrapperCol={{ span: 16, offset: 8 }}>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Guardar</Button>
            </FormItem>

          </Form>
        </Card>
      </Spin>
    </Card>
  );
}

export default ParametrosForm;

